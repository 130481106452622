import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { COLORS } from '../../themes/colors';

const drawerWidthDefault = 320;
const drawerCloseDefault = 96;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerOpenWidth?: number;
  drawerCloseWidth?: number;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  hasSubHeader?: boolean;
}
export const AppBar = muiStyled(
  ({
    drawerOpenWidth,
    drawerCloseWidth,
    innerRef,
    hasSubHeader,
    ...props
  }: AppBarProps) => <MuiAppBar ref={innerRef} {...props} />,
  {
    shouldForwardProp: prop => prop !== 'open',
  },
)<AppBarProps>(
  ({ theme, open, drawerOpenWidth, drawerCloseWidth, hasSubHeader }) => ({
    zIndex: theme.zIndex.drawer - 1,
    padding: '16px 0',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: COLORS.WHITE,
    boxShadow: 'none',
    height: hasSubHeader ? '175px' : '85px',

    [theme.breakpoints.down('lg')]: {
      height: 'auto',
    },

    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - (${drawerCloseWidth || drawerCloseDefault}px + 1px))`,
      ...(open && {
        marginLeft: drawerOpenWidth || drawerWidthDefault,
        width: `calc(100% - ${drawerOpenWidth || drawerWidthDefault}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    },
  }),
);

export const AppBarContainer = muiStyled(Container)(() => ({}));

export const MenuButton = muiStyled(Button)(() => ({
  color: COLORS.MONOCHROMATIC.GRAY4,
  fontWeight: 400,
  fontSize: 14,
  '& .MuiButton-startIcon': {
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
}));

export const PageTitleContainer = muiStyled(Stack)(() => ({
  svg: {
    color: COLORS.PRIMARY.ORANGE.MAIN,
    fontSize: 32,
  },

  '.icon-chevron-right': {
    color: COLORS.MONOCHROMATIC.GRAY4,
  },

  height: '100%',
  alignItems: 'center',
}));

export const SubRouteName = muiStyled(Typography)(() => ({
  fontSize: 16,
}));

export const RouteName = muiStyled(Typography)(() => ({
  fontSize: 16,
  IconSize: '24px',
  fontFamily: 'Source Sans Pro, sans-serif',
  fontWeight: 900,
  lineHeight: '20px',
  letterSpacing: 0,
  textAlign: 'left',
}));

export const MobileDivider = muiStyled(Divider)(({ theme }) => ({
  width: 'calc(100% + 32px)',
  margin: '20px 0 16px -16px',
  backgroundColor: COLORS.MONOCHROMATIC.GRAY3,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const IconWrapper = muiStyled("span")(() => ({
  cursor: 'pointer',
}));
