import { ReactNode, SyntheticEvent, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import TableView from './TableView';

import { TableOrder } from '../../utils/table';
import { StyledPaper, StyledTab } from './styles';
import { COLORS } from '../../themes/colors';
import { Box } from '@mui/material';

interface TabsProps {
  id: number;
  label: string;
}

type TableHeadProps = {
  id: number;
  label: string;
  name: string;
  size?: number;
  hide?: boolean;
};

interface TabsTableViewProps {
  title: string;
  subtitle?: string;
  tabs: TabsProps[];
  data: ReactNode[];
  tableOrder?: TableOrder | null;
  tableHeadColumns: TableHeadProps[];
  onSelectTab?: (tab: TabsProps) => void;
  onOrderChange?(order: 'asc' | 'desc', name: string): void;
  onTableOrderChange: (tableOrder: TableOrder | null) => void;
  hasInfiniteScroll?: boolean;
  loadNextPage?: () => Promise<void>;
  maxHeight?: number;
  isLoading?: boolean;
  actionButton?: ReactNode;
}

const TabsTableView: React.FC<TabsTableViewProps> = ({
  tabs,
  data,
  title,
  subtitle,
  tableOrder,
  tableHeadColumns,
  onSelectTab,
  onOrderChange,
  onTableOrderChange,
  hasInfiniteScroll = false,
  loadNextPage,
  maxHeight,
  isLoading = false,
  actionButton,
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    const selectedTab = tabs[newValue];
    onSelectTab && onSelectTab(selectedTab);
    setValue(newValue);
  };

  return (
    <>
      <Grid item xs={12}>
        <StyledPaper elevation={0}>
          <Grid container display="flex" direction="column">
            <Grid item>
              <Box display="flex" alignItems="center" sx={{ p: 3 }}>
                <Stack gap={1} width="100%">
                  <Typography variant="subtitle1">{title}</Typography>
                  {subtitle && (
                    <Typography variant="body2">{subtitle}</Typography>
                  )}
                </Stack>
                {actionButton}
              </Box>
            </Grid>

            <Grid item>
              <Tabs
                value={value}
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                onChange={handleChange}
              >
                {tabs.map(tab => (
                  <StyledTab
                    key={tab.label}
                    label={
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography>{tab.label}</Typography>
                      </Stack>
                    }
                  />
                ))}
              </Tabs>

              {tabs.map((_tab, index) => (
                <Stack gap={2} key={index}>
                  {index === value && (
                    <TableView
                      TableHeadColumns={tableHeadColumns}
                      list={data}
                      tableOrder={tableOrder}
                      onOrderChange={onOrderChange}
                      onTableOrderChange={onTableOrderChange}
                      hasInfiniteScroll={hasInfiniteScroll}
                      loadNextPage={loadNextPage}
                      maxHeight={maxHeight}
                    />
                  )}
                </Stack>
              ))}

              {isLoading && (
                <Stack
                  height={maxHeight ?? 80}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress
                    style={{ color: COLORS.PRIMARY.ORANGE.MAIN }}
                  />
                </Stack>
              )}
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </>
  );
};

export default TabsTableView;
