import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Gender } from '../../constants/enums';
import {
  Filter,
  FiltersGroup,
  useDrawerFilter,
} from '../../hooks/useDrawerFilter';
import { COLORS } from '../../themes/colors';
import Checkbox from '../Checkbox';
import Icon from '../Icon';
import PulsarAnimationLoading from '../PulsarAnimation';
import {
  FieldWrapper,
  FilterButton,
  Header,
  SelectAll,
  StyledCollapse,
  Wrapper,
} from './styles';

interface LoadNextPageReturn {
  filters: Filter[];
  hasMore: boolean;
}

interface DrawerFilterProps {
  filterKey: string;
  title: string;
  label: string;
  singularLabel?: string;
  gender?: Gender;
  filters: Filter[];
  isMultiple?: boolean;
  loadNextPage?: (
    skip: number,
  ) => Promise<LoadNextPageReturn> | LoadNextPageReturn;
}

const DrawerFilter = ({
  title,
  label,
  singularLabel,
  gender,
  filters: startFilters,
  filterKey,
  isMultiple = true,
  loadNextPage,
}: DrawerFilterProps) => {
  const [open, setOpen] = useState(false);

  const [skip, setSkip] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState(startFilters);

  const {
    activeFilters,
    setActiveFilters,
    provFilters,
    setProvFilters,
    setInitialFilters,
    initialFilters,
    register,
    unregister,
  } = useDrawerFilter();

  const currentFilters = useMemo(
    () => provFilters[filterKey]?.filters ?? [],
    [provFilters, filterKey],
  );

  const selectAll = useMemo(
    () => filters.length === currentFilters.length,
    [filters, currentFilters],
  );

  const isSelected = (filterVal: string | number) =>
    currentFilters.some(filter => filter.value === filterVal);

  const handleChange = (filter: Filter) => {
    if (isMultiple) {
      setProvFilters(prev => {
        let newFilters = prev[filterKey]?.filters ?? [];

        if (isSelected(filter.value))
          newFilters = newFilters.filter(f => f.label !== filter.label);
        else newFilters = [...newFilters, filter];

        return {
          ...prev,
          [filterKey]: {
            ...prev[filterKey],
            filters: newFilters,
          },
        };
      });
    } else {
      const sameFilter = provFilters[filterKey]?.filters?.some(
        f => f.label === filter.label,
      );

      setProvFilters(prev => {
        return {
          ...prev,
          [filterKey]: {
            ...prev[filterKey],
            filters: sameFilter ? [] : [filter],
          },
        };
      });
    }
  };

  const handleSelectAll = () => {
    setProvFilters(prev => {
      return {
        ...prev,
        [filterKey]: {
          ...prev[filterKey],
          filters: selectAll ? [] : filters,
        },
      };
    });
  };

  useEffect(() => {
    setProvFilters(prev => ({
      ...prev,
      [filterKey]: {
        gender,
        singularLabel,
        label,
        filters: activeFilters[filterKey]?.filters ?? [],
      },
    }));

    if (initialFilters[filterKey] === undefined)
      setInitialFilters(prev => ({
        ...prev,
        [filterKey]: {
          gender,
          singularLabel,
          label,
          filters,
          isDynamic: loadNextPage !== undefined,
        },
      }));

    if (loadNextPage) {
      if (initialFilters[filterKey] !== undefined) {
        const loadedFilters = initialFilters[filterKey].filters ?? [];

        setHasMore(initialFilters[filterKey].hasMore ?? true);

        if (loadedFilters.length > 0) {
          setSkip(skip + loadedFilters.length);
          setFilters(prev => [...prev, ...loadedFilters]);
        }
      } else nextPageHandler();
    }

    register(filterKey);

    return () => {
      unregister(filterKey);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelectAll = () =>
    isMultiple && (
      <SelectAll>
        <Checkbox
          label="Selecionar todos"
          checked={selectAll}
          onChange={handleSelectAll}
        />
        <Typography>Selecionar todos</Typography>
      </SelectAll>
    );

  const renderFiltersList = () =>
    filters.map(filter => (
      <FieldWrapper
        key={filter.value}
        onClick={() => {
          handleChange(filter);
        }}
      >
        <Checkbox label={filter.label} checked={isSelected(filter.value)} />
        {typeof filter.content === 'string' ? (
          <Typography>{filter.content}</Typography>
        ) : (
          filter.content
        )}
      </FieldWrapper>
    ));

  const renderContent = () => {
    if (isLoading && filters.length === 0)
      return (
        <Box display="flex" justifyContent="center" mt={2} mb={2}>
          <PulsarAnimationLoading
            width="30px"
            height="30px"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
        </Box>
      );

    return (
      <>
        {renderSelectAll()}

        <Stack pl="4px" pt="8px" pb="8px" maxHeight="265px" overflow="auto">
          {loadNextPage ? (
            <InfiniteScroll
              height={265}
              dataLength={filters.length}
              next={nextPageHandler}
              hasMore={hasMore}
              loader={<></>}
            >
              {renderFiltersList()}
            </InfiniteScroll>
          ) : (
            renderFiltersList()
          )}
        </Stack>
      </>
    );
  };

  const nextPageHandler = async () => {
    setIsLoading(true);
    const { filters: apiNewFilters, hasMore } = await loadNextPage!(skip);

    const updateFilters = (previousFilters: FiltersGroup) => {
      if ((previousFilters[filterKey]?.filters?.length ?? 0) > 0) {
        const newFilters = previousFilters[filterKey]!.filters!.map(f => {
          const newFilter = apiNewFilters.find(
            apiFilter => apiFilter.value === f.value,
          );

          return newFilter ? newFilter : f;
        });

        return {
          ...previousFilters,
          [filterKey]: {
            ...previousFilters[filterKey],
            filters: newFilters,
          },
        };
      }

      return previousFilters;
    };

    setActiveFilters(prev => updateFilters(prev));
    setProvFilters(prev => updateFilters(prev));

    setInitialFilters(prev => ({
      ...prev,
      [filterKey]: {
        ...prev[filterKey],
        filters: [...(prev[filterKey]?.filters ?? []), ...apiNewFilters],
        hasMore,
      },
    }));
    setFilters(prev => [...prev, ...apiNewFilters]);

    setSkip(prev => prev + apiNewFilters.length);
    setHasMore(hasMore);

    setIsLoading(false);
  };

  return (
    <Wrapper
      disabled={filters.length === 0 || isLoading}
      bgcolor={open ? COLORS.WHITE : COLORS.MONOCHROMATIC.GRAY1}
      sx={{ position: 'relative' }}
    >
      <Header
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Stack>
          {isLoading && (
            <Box
              top={0}
              left={0}
              right={0}
              bottom={0}
              position="absolute"
              sx={{ placeItems: 'center' }}
              display="grid"
            >
              <PulsarAnimationLoading
                width="30px"
                height="30px"
                color={COLORS.PRIMARY.ORANGE.MAIN}
              />
            </Box>
          )}
          <Typography variant="text" mb={0.5}>
            {title}
          </Typography>
          {currentFilters.length > 0 && !selectAll ? (
            <Box flexWrap="wrap" display="flex">
              {currentFilters.map(filter => (
                <FilterButton mt={1} key={filter.value}>
                  <Typography>{filter.label}</Typography>
                  <Icon
                    onClick={e => {
                      e.stopPropagation();
                      handleChange(filter);
                    }}
                    name="close"
                    size={16}
                    color={COLORS.WHITE}
                  />
                </FilterButton>
              ))}
            </Box>
          ) : (
            <>
              <Typography variant="smallText">
                {filters.length === 0
                  ? 'Nenhum filtro disponível'
                  : selectAll
                  ? 'Todos selecionados'
                  : 'Nenhum selecionado'}
              </Typography>
            </>
          )}
        </Stack>

        <Icon name={open ? 'chevron-up' : 'chevron-down'} size={24} />
      </Header>

      <StyledCollapse in={open}>{renderContent()}</StyledCollapse>
    </Wrapper>
  );
};

export default DrawerFilter;
