import { styled as muiStyled } from '@mui/material';
import Stack from '@mui/material/Stack';

interface ContainerProps {
  borderColor: string;
  backgroundColor: string;
}

const containerProps = ['backgroundColor', 'borderColor'];

export const Container = muiStyled('div', {
  shouldForwardProp: (propName: string) => !containerProps.includes(propName),
})<ContainerProps>(({ backgroundColor, borderColor }) => ({
  display: 'flex',
  borderRadius: '8px',
  background: backgroundColor,
  border: `1px solid ${borderColor}`,
  padding: '1.5rem',
  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
}));

export const TextWrapper = muiStyled(Stack)(() => ({
  '& .MuiTypography-root:first-of-type': {
    marginBottom: '0.75rem',
  },
  margin: '-2px 0 0 1rem',
}));
