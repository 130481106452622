import { Modal } from '@hapvida/hapvida-core-components';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  ConfirmationModalContext,
  IShowConfirmation,
} from '../contexts/ConfirmationModalContext';

interface ModalDataProps extends IShowConfirmation {
  open: boolean;
};

const defaultModal: ModalDataProps = {
  open: false,
  onCancel: async () => {},
  onConfirm: async () => {},
  icon: 'alert',
  type: 'default',
  cancelText: undefined,
  confirmText: undefined,
  title: 'Deseja continuar?',
  content: <></>,
}

const ConfirmationProvider: React.FC = ({ children }) => {
  const [modalData, setModalData] = useState<ModalDataProps>(defaultModal);

  const handleConfirmation = useCallback((data: ModalDataProps) => {
    setModalData({ ...defaultModal, ...data, open: true });
  }, []);

  const handleClose = () => setModalData(prev => ({ ...prev, open: false }));

  const handleConfirm = async () => {
    handleClose();
    if(modalData.onConfirm) await modalData.onConfirm();
  };

  const handleCancel = async () => {
    handleClose();
    if(modalData.onCancel) await modalData.onCancel();
  }

  return (
    <ConfirmationModalContext.Provider
      value={{ showConfirmation: handleConfirmation }}
    >
      <Modal
        mainAction={handleConfirm}
        handleCloseModal={handleCancel}
        mainButtonText={modalData.confirmText}
        secondaryButtonText={modalData.cancelText}
        type={modalData.type}
        open={modalData.open}
        icon={modalData.icon}
        title={modalData.title}
      >
        {modalData.content}
      </Modal>
      {children}
    </ConfirmationModalContext.Provider>
  );
};

function useConfirmation() {
  const context = useContext(ConfirmationModalContext);

  if (!context) {
    throw new Error('useConfirmation must be used within ConfirmationProvider');
  }

  return context;
}

export { ConfirmationProvider, useConfirmation };
