import { DataText, Icon, Mask, Typography } from '@hapvida/hapvida-core-components';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Collapse, Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Matrix, CompanyName, DivIcon } from './styles';

export interface CompanyDetails {
  fantasyName: string;
  document: string;
  corporateName: string;
  state: string;
  city: string;
  exclusivity: string;
  commissioning: string;
  createdAt: string;
  lastModifiedAt: string;
  joinedCompaniesFile: any;
  url: string;
  affiliatesData: any[];
  lives?: number;
  holders?: number;
  dependents?: number;
  aggregates?: number;
}

export interface Fields<T extends keyof CompanyDetails> {
  label: string;
  key: T;
  formatter?: (input: CompanyDetails[T]) => ReactNode;
}

interface CollapsableCompanyProps {
  matrix?: boolean;
  company: CompanyDetails;
  fields: Fields<keyof CompanyDetails>[];  
  openedCard: string;
  setOpenedCard(state: string): void;
}

export const CollapsableCompany = ({ matrix, company, fields, openedCard, setOpenedCard }: CollapsableCompanyProps) => {
  const handleClick = () => {
    if(openedCard === company.document) {
      setOpenedCard("");
    } else {
      setOpenedCard(company.document);
    }
  }

 return (
    <Stack
      onClick={handleClick}
      sx={{
        p: 2,
        backgroundColor: openedCard === company.document ? COLORS.MONOCHROMATIC.GRAY1 : 'white',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease-in-out',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <DivIcon>
            <Icon name="building" size={24} color={COLORS.PRIMARY.BLUE.MAIN} />
          </DivIcon>
          <span>
            {matrix && <Matrix>MATRIZ</Matrix>}
            <CompanyName>{company.corporateName}</CompanyName>
            <Typography variant="smallText">{Mask.cnpj(company.document)}</Typography>
          </span>
        </Stack>
        <Icon size={24} name={openedCard === company.document ? 'chevron-up' : 'chevron-down'} />
      </Stack>

      <Collapse in={openedCard === company.document}>
        <Stack gap={2} sx={{ mt: 1 }}>
          {fields.map(({ label, key, formatter }, index) => (
            <DataText
              key={`${key}-${index}`}
              field={label}
              value={formatter ? formatter(company[key]) : company[key]}
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
