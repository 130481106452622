import { Grid } from '@mui/material';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GridViewContainer } from './styles';

interface LineViewListProps<T = object> {
  data?: Array<T>;
  hasNextPage?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  loadNextPage?: () => Promise<void>;
  renderRow(data: T): any;
  listContainerSize?: number | string | undefined;
  additionalComponent?: JSX.Element;
}

const GridViewList: React.FC<LineViewListProps> = ({
  data = [],
  hasNextPage,
  xs,
  sm,
  md,
  lg,
  xl,
  loadNextPage,
  renderRow,
  listContainerSize,
  additionalComponent,
}) => {
  return (
    <GridViewContainer id="scrollableDiv" listsize={listContainerSize}>
      {additionalComponent}
      <InfiniteScroll
        dataLength={data.length}
        hasMore={!!hasNextPage}
        next={() => loadNextPage && loadNextPage()}
        loader={<></>}
        scrollableTarget="scrollableDiv"
      >
        <Grid container spacing={4}>
          {data.map((quotation, index) => (
            <Grid key={index} item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
              {renderRow(quotation)}
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </GridViewContainer>
  );
};

export default GridViewList;
