import React, { useCallback, useRef } from 'react';
import { Avatar, Grid, Stack } from '@mui/material';

import Typography from '../Typography';

import { Username, Container, LogoutButton, MobileHiddenStack } from './styles';
import Icon from '../Icon';

interface UserMenuProps {
  username?: string;
  url?: string;
  role?: string;

  onLogOut?(): void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  username,
  role,
  url,
  onLogOut,
}) => {
  const chevronRef = useRef(null);

  const renderUserInitals = useCallback(() => {
    if (username) {
      const [name, lastname] = username.split(' ');

      if (name && lastname) {
        return `${name[0]}${lastname[0]}`;
      }

      if (name) {
        return name[0];
      }
    }
  }, [username]);

  return (
    <>
      <Container
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        marginLeft={1}
      >
        <Avatar src={url} alt={username}>
          {renderUserInitals()}
        </Avatar>
        <Grid item marginLeft={1}>
          <Stack ref={chevronRef} gap={2} direction="row" alignItems="center">
            <MobileHiddenStack>
              <Username variant="subtitle1">{username}</Username>
              <Typography variant="smallText">{role}</Typography>
            </MobileHiddenStack>
            <Stack>
              <LogoutButton
                variant="text"
                startIcon={<Icon name="side-bar-open" />}
                onClick={onLogOut}
              >
                Sair
              </LogoutButton>
            </Stack>
          </Stack>
        </Grid>
      </Container>
    </>
  );
};

export default UserMenu;
