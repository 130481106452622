import { useEffect, useState } from 'react';

import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';

import {
  QuotationStatusEnum,
  quotationEnumKeys,
} from '../../../../constants/enums';

import { User, Text } from './styles';

import formatDateISO from '../../../../utils/formatDateISO';

interface QuotationHistoryBaseItem {
  createdAt: string;
  status: QuotationStatusEnum;
  userDetail?: {
    name: string;
    id: string;
  };
  observation: string;
}

interface InnerStepsProps {
  mainStatus: QuotationStatusEnum;
  additionalInformation: QuotationHistoryBaseItem[];

  QuotationStepIcon: (
    status: QuotationStatusEnum,
    color: string,
  ) => JSX.Element;
}

const InnerSteps = ({
  mainStatus,
  QuotationStepIcon,
  additionalInformation,
}: InnerStepsProps) => {
  const [rgb, setRgb] = useState('');

  useEffect(() => {
    if (mainStatus) {
      const normal = quotationEnumKeys[mainStatus].color.match(
        /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i,
      );
      if (normal) {
        const rgb = normal.slice(1).map(e => parseInt(e, 16));
        const rgbRes = `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`;

        setRgb(rgbRes);
      }
    }
  }, [mainStatus]);

  const renderUserDate = (currentItem: QuotationHistoryBaseItem) => {
    if (currentItem.userDetail)
      return (
        <Grid item xs={12}>
          <User>
            <Avatar
              alt={currentItem.userDetail.name}
              src=""
              sx={{ width: 12, height: 12 }}
            />
            &nbsp; &nbsp;
            <Text uppercase>{currentItem.userDetail.name}</Text>
          </User>
        </Grid>
      );
  };

  return (
    <Paper
      square
      elevation={0}
      sx={{
        p: 3,
        borderRadius: '8px',
        background: `rgba(${rgb}, 0.04)`,
        padding: '16px 24px',
      }}
    >
      <Stepper orientation="vertical">
        {additionalInformation.map((information, index) => (
          <Step key={`subStep-${index}`} active>
            <StepLabel
              StepIconComponent={() =>
                QuotationStepIcon(
                  information.status,
                  quotationEnumKeys[mainStatus].color,
                )
              }
            />
            <StepContent>
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <Text>
                    <strong>{information.observation}</strong>
                  </Text>
                </Grid>

                {renderUserDate(information)}

                <Grid item xs={12}>
                  <Typography variant="caption">
                    Iniciado{' '}
                    <strong>
                      {formatDateISO(
                        information.createdAt,
                        "dd 'de' MMMM yyyy",
                      )}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Paper>
  );
};

export default InnerSteps;
