import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FiltersGroup, useDrawerFilter } from '../../hooks/useDrawerFilter';
import { COLORS } from '../../themes/colors';
import FiltersList from '../FiltersList';
import { OverflowStrip } from '../OverflowStrip';

interface FilterSummaryProps {
  totalLabel?: string;
  inLabel: string;
  withoutFilterLabel?: string;
  alwaysShowTitle?: boolean;
  onApplyFilters?: (filter: FiltersGroup) => void;
  disabledOverflowStrip?: boolean;
}

const FilterSummary = ({
  totalLabel,
  inLabel,
  withoutFilterLabel,
  alwaysShowTitle = false,
  onApplyFilters,
  disabledOverflowStrip = false,
}: FilterSummaryProps) => {
  const { filterCount } = useDrawerFilter();

  const getTitle = () => {
    if (totalLabel)
      return (
        <Typography component="div" variant="subtitle1" mb={0.5}>
          Total de{' '}
          <Typography
            variant="subtitle1"
            display="inline"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          >
            {totalLabel}
          </Typography>
        </Typography>
      );

    return null;
  };

  return (
    <>
      {filterCount > 0 && (
        <OverflowStrip
          color={disabledOverflowStrip ? 'transparent' : undefined}
        >
          <Stack sx={{ py: '14px' }}>
            {totalLabel && getTitle()}

            <Typography variant="text">
              Você tem{' '}
              <b>
                {filterCount === 1
                  ? 'esse filtro aplicado'
                  : 'esses filtros aplicados'}{' '}
              </b>{' '}
              {inLabel}
              {':'}
            </Typography>

            <FiltersList onApplyFilters={onApplyFilters} />
          </Stack>
        </OverflowStrip>
      )}

      {filterCount === 0 && alwaysShowTitle && <Stack>{getTitle()}</Stack>}

      {filterCount === 0 && withoutFilterLabel && (
        <Typography variant="subtitle1">{withoutFilterLabel}</Typography>
      )}
    </>
  );
};

export default FilterSummary;
