import { Icon } from '@hapvida/hapvida-core-components';
import { Box, SelectChangeEvent, Typography } from '@mui/material';

import { useController } from 'react-hook-form';
import { COLORS } from '../../themes/colors';

import {
  StyledFormControl,
  StyledInputLabel,
  StyledMenuItem,
  StyledSelect,
  useStyles,
} from './styles';

const CREATE_PROFILE_VALUE = 'create-profile';

interface ProfilesSelectProps {
  name: string;
  control: any;
  options: {
    value: string | number;
    label: string;
  }[];
  title?: string;
  onRedirect?: () => void;
  hasRedirect?: boolean;
}

const ProfilesSelect = ({
  name,
  control,
  options,
  title = '* Perfil de acesso',
  onRedirect = () => {},
  hasRedirect = true,
}: ProfilesSelectProps) => {
  const {
    field: { value, onChange },
  } = useController({ name, control });

  const classes = useStyles();

  const handleChange = ({ target: { value } }: SelectChangeEvent<unknown>) => {
    if (value === CREATE_PROFILE_VALUE) {
      onRedirect();
      return;
    }

    onChange(value);
  };

  return (
    <StyledFormControl>
      <StyledInputLabel>{title}</StyledInputLabel>
      <StyledSelect
        displayEmpty
        value={value}
        onChange={handleChange}
        IconComponent={props => (
          <Icon
            name="chevron-down"
            color={COLORS.MONOCHROMATIC.GRAY4}
            size={24}
            {...props}
          />
        )}
        sx={{ border: 0 }}
        MenuProps={{ classes: { paper: classes.select } }}
      >
        <StyledMenuItem
          value=""
          sx={{
            display: 'none',
          }}
        >
          Selecione
        </StyledMenuItem>
        {options.map(({ value, label }) => (
          <StyledMenuItem key={value} value={value}>
            {label}
          </StyledMenuItem>
        ))}
        {hasRedirect && (
          <StyledMenuItem value={CREATE_PROFILE_VALUE}>
            <Box display="flex" alignItems="center" p="6px 0">
              <Icon name="plus" color={COLORS.PRIMARY.BLUE.MAIN} size={24} />
              <Typography
                fontWeight={700}
                color={COLORS.PRIMARY.BLUE.MAIN}
                ml={1}
              >
                Criar novo perfil de acesso
              </Typography>
            </Box>
          </StyledMenuItem>
        )}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default ProfilesSelect;
