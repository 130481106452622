import {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import TextInput from '../../TextInput';
import {
  OptionsContainer,
  PopperLabel,
  StyledAutoComplete,
  StyledLabel,
  StyledMessage,
} from './styles';

interface AutoCompleteProps {
  options: Array<{
    [x: string]: string | number;
  }>;
  searchLabel: string;
  name?: string;
  label?: string;
  optionLabel?: string;
  open?: boolean;
  control: any;
  required?: boolean;
  message?: string;
  typedValue?: string;
  onOptionSelected?(option: any): void;
  renderOption?(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: unknown,
    state: AutocompleteRenderOptionState,
  ): React.ReactNode;
  mask?: Function;
  getTypedValue?(value: string): void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
}

const AutoComplete: React.FC<AutoCompleteProps> = ({
  options,
  searchLabel,
  name,
  label,
  optionLabel,
  open,
  control,
  required = true,
  message,
  mask,
  typedValue = '',
  renderOption,
  onOptionSelected,
  getTypedValue,
  onKeyDown,
}) => {
  const [inputValue, setInputValue] = useState(typedValue);

  return (
    <>
      <StyledLabel required={required}>{label}</StyledLabel>
      <Controller
        name={name || ''}
        control={control}
        render={({ field: { onChange } }) => (
          <StyledAutoComplete
            freeSolo
            open={open}
            onChange={(e, value) => {
              onChange(value);
              if (onOptionSelected) {
                onOptionSelected(value);
              }
            }}
            options={options}
            getOptionLabel={(option: any) => option[searchLabel] ?? option}
            PaperComponent={({ children }: any) =>
              !!children[2]?.props ? (
                <OptionsContainer>
                  {optionLabel && <PopperLabel>{optionLabel}</PopperLabel>}
                  {children}
                </OptionsContainer>
              ) : null
            }
            renderOption={renderOption}
            inputValue={mask ? mask(inputValue) : inputValue}
            onInputChange={(_e, value) => {
              setInputValue(value);
              getTypedValue && getTypedValue(value);
            }}
            onKeyDown={onKeyDown}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextInput {...params} InputProps={params.InputProps} />
            )}
          />
        )}
      />

      {message && <StyledMessage>{message}</StyledMessage>}
    </>
  );
};

export default AutoComplete;
