import { TextField } from '@mui/material';

import { styled as muiStyled } from '@mui/material/styles';

import styled, { css } from 'styled-components';

// import { Typography } from '@hapvida/hapvida-core-components';
import Typography from '../Typography';

import { COLORS } from './../../themes/colors/index';

import Icon from '../Icon';

export const SuccessIcon = styled(Icon)`
  color: ${COLORS.SECONDARY.GREEN['+1']};
`;

export const ErrorIcon = styled(Icon)`
  color: ${COLORS.SECONDARY.RED.MAIN};
`;

export const StyledTextInput = styled(TextField)`
  ${({ value, error = false }) =>
    !!value &&
    !error &&
    css`
      .MuiInputBase-root {
        border: 1px solid ${COLORS.PRIMARY.BLUE.MAIN};
      }
    `}
`;

export const ErrorMessage = muiStyled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontSize: '0.75rem',
    lineHeigh: '0.938rem',

    color: COLORS.SECONDARY.RED.MAIN,

    marginTop: '0.3rem',
  },
}));
