import Icon from '../Icon';
import { COLORS } from '../../themes/colors';
import formatDateISO from '../../utils/formatDateISO';
import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Container, Date } from './styles';
interface CardProps {
  children: React.ReactNode;
  title: string;
  md: number;
  date?: string | undefined;
  onEdit?: () => void;
}

const Card = ({ children, title, md, date, onEdit }: CardProps) => {
  return (
    <Grid item md={md}>
      <Container>
        <Stack gap={2}>
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack >
              <Typography variant="subtitle1">{title}</Typography>
              {date ? (
                <Date>
                  Realizado{' '}
                  <strong>
                    {formatDateISO(date, "dd 'de' MMMM 'de' yyyy")}
                  </strong>
                </Date>
              ) : null}
            </Stack>
            {onEdit && (
              <Icon
                name="edit"
                variant="outlined"
                onClick={onEdit}
                size={20}
                color={COLORS.MONOCHROMATIC.GRAY4}
              />
            )}
          </Grid>

          <Grid item>{children}</Grid>
        </Stack>
      </Container>
    </Grid>
  );
};

export default Card;
