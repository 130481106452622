import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import {
  Card,
  Arrow,
  Header,
  Content,
  CurrentStep,
  CardsContainer,
  ArrowWrapper,
  IconContainer,
} from './styles';

interface StepCardProps {
  steps: {
    id: number;
    img: string;
    content: string | JSX.Element;
    alt?: string;
  }[];
  cardStyle?: React.CSSProperties;
}

const StepCard: React.FC<StepCardProps> = ({ steps, cardStyle }) => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  return (
    <CardsContainer>
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          <Card sx={cardStyle}>
            <Header>
              <img src={step.img} alt={step.alt} />
              <CurrentStep>{index + 1}</CurrentStep>
            </Header>
            <Content>{step.content}</Content>
          </Card>
          <ArrowWrapper>
            <IconContainer position={index} length={steps.length}>
              <Arrow
                name={isMobile ? 'chevron-down' : 'chevron-right'}
                size={24}
              />
            </IconContainer>
          </ArrowWrapper>
        </React.Fragment>
      ))}
    </CardsContainer>
  );
};

export default StepCard;
