import { InputAdornment, TextFieldProps } from '@mui/material';
import { useEffect } from 'react';
import { useController } from 'react-hook-form';
import { COLORS } from '../../../themes/colors';
import Icon from '../../Icon';
import { IconName } from '../../Icon/types';
import {
  ErrorIcon,
  ErrorMessage,
  StyledTextInput,
  SuccessIcon,
} from '../styles';

type ControlledTextInputProps = TextFieldProps & {
  name: string;
  control: any;
  errorMessage?: string;
  mask?: Function;
  iconStart?: IconName;
};

const ControlledTextInput = ({
  variant = 'filled',
  name,
  color,
  error,
  InputProps,
  FormHelperTextProps,
  control,
  errorMessage,
  mask,
  iconStart,
  required,
  ...props
}: ControlledTextInputProps) => {
  const {
    field: { onChange, value },
    fieldState: { isDirty },
  } = useController({ name, control });

  useEffect(() => {
    if (mask && !isDirty && value !== undefined) {
      onChange(mask(value));
    }
  }, [isDirty, mask, onChange, value]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;

    if (mask) e.target.value = mask(value);

    onChange(e);
  };

  return (
    <>
      <StyledTextInput
        color={color}
        error={error}
        required={required}
        onChange={handleChange}
        value={value}
        variant={variant}
        InputLabelProps={{
          style: {
            fontSize: 14,
            ...(required && { color: COLORS.PRIMARY.BLUE['+1'] }),
          },
        }}
        FormHelperTextProps={{
          ...FormHelperTextProps,
          ...(color === 'success' && {
            style: {
              color: COLORS.SECONDARY.GREEN['+1'],
            },
          }),
        }}
        InputProps={{
          ...InputProps,
          required,
          ...(color === 'success' && {
            endAdornment: (
              <InputAdornment position="end">
                <SuccessIcon name="check" />
              </InputAdornment>
            ),
          }),
          ...(error && {
            endAdornment: (
              <InputAdornment position="end">
                <ErrorIcon name="alert" />
              </InputAdornment>
            ),
          }),
          ...(iconStart && {
            startAdornment: (
              <InputAdornment position="end">
                <Icon name={iconStart} size={24} />
              </InputAdornment>
            ),
          }),
        }}
        {...props}
      />
      {errorMessage && error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

export default ControlledTextInput;
