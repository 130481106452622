import {
  Box,
  CSSObject,
  Theme,
  styled as muiStyled,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  Stack,
} from '@mui/material';
import { COLORS } from '../../../themes/colors';

const drawerWidth = '100%';
const drawerClose = 500;
const drawerIsMobile = 300;

export const PageContainer = muiStyled(Box)(() => ({
  display: 'flex',
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: 600,
  }),
  overflowX: 'hidden',
  width: `calc(${drawerIsMobile}px + 1px)`,
  [theme.breakpoints.up('md')]: {
    width: `calc(${drawerClose}px + 1px)`,
  },
});

interface DrawerProps {
  isFirstStep: boolean;
  startImage?: string;
  showImage?: boolean;
  firstStepColor?: string;
}

export const Drawer = muiStyled(
  ({
    isFirstStep,
    startImage,
    showImage,
    firstStepColor,
    ...props
  }: DrawerProps & MuiDrawerProps) => <MuiDrawer {...props} />,
)<DrawerProps>(
  ({
    theme,
    open,
    isFirstStep,
    startImage,
    showImage,
    firstStepColor = COLORS.PRIMARY.ORANGE.MAIN,
  }) => ({
    width: 500,
    zIndex: theme.zIndex.drawer,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
    '.MuiPaper-root': {
      borderRight: 'none',
    },
    '.MuiDrawer-paper': {
      [theme.breakpoints.up('md')]: {
        background: showImage ? `url(${startImage}) no-repeat` : 'none',
        backgroundPosition: '100% 0px',
        backgroundSize: 'contain',
        backgroundAttachment: 'fixed',
        width: 500,
      },
    },
    [theme.breakpoints.up('md')]: {
      ...(showImage && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          ...openedMixin(theme),
          backgroundColor: isFirstStep ? firstStepColor : COLORS.WHITE,
        },
      }),
      ...(!showImage && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': {
          ...closedMixin(theme),
          backgroundColor: isFirstStep ? firstStepColor : COLORS.WHITE,
        },
      }),
    },

    [theme.breakpoints.down('sm')]: {
      ...((showImage || !showImage) && {
        '& .MuiDrawer-paper': {
          backgroundColor: isFirstStep ? firstStepColor : COLORS.WHITE,
        },
      }),
    },
  }),
);

interface MainContentProps {
  headerOffset: string;
}

export const MainContent = muiStyled(Box)<MainContentProps>(
  ({ headerOffset }) => ({
    flexGrow: 1,
    marginTop: `calc(${headerOffset} + 24px)`,
  }),
);

export const LogoContainer = muiStyled(Stack)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    img: {
      width: 102,
    },
  },
}));

export const FirstStepContent = muiStyled(Stack)(({ theme }) => ({
  marginTop: 120,
  padding: '16px 80px',
  maxWidth: 500,
  overflowWrap: 'break-word',
  whiteSpace: 'break-spaces',
  color: COLORS.WHITE,
  h4: {
    width: '100%',
    maxWidth: 280,
    wordWrap: 'break-word !important',
    overflowWrap: 'break-word !important',
    wordBreak: 'break-word !important',
    color: COLORS.WHITE,
  },
  span: {
    color: COLORS.WHITE,
  },

  marginLeft: 95,

  [theme.breakpoints.down('sm')]: {
    width: '70% !important',
    padding: '0px !important',
    marginLeft: '50px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const StartImage = muiStyled('img')(() => ({}));
