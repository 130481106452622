import React from 'react';

import { Container, ViewIcon } from './styles';

interface ToogleListViewProps {
  view: 'table' | 'grid';
  onViewChange(view: 'table' | 'grid'): void;
}

const ToogleListView: React.FC<ToogleListViewProps> = ({
  view,
  onViewChange,
}) => {
  return (
    <Container>
      <ViewIcon
        activate={view === 'grid'}
        onClick={() => onViewChange('grid')}
        name="grid-view"
      />
      <ViewIcon
        activate={view === 'table'}
        onClick={() => onViewChange('table')}
        name="table-view"
      />
    </Container>
  );
};

export default ToogleListView;
