import Avatar from '@mui/material/Avatar';

import { User, Text, Dot } from './styles';

interface UserInformationProps {
  name: string;
  avatar: string;
  occupation?: string;
}

const UserInformation = ({
  name,
  avatar,
  occupation,
}: UserInformationProps) => {
  return (
    <User>
      <Avatar alt={name} src={avatar} sx={{ width: 18, height: 18 }} />
      &nbsp; &nbsp;
      <Text uppercase>{name}</Text>
      {occupation && (
        <>
          <Dot>&#8226;</Dot>
          <Text>{occupation}</Text>
        </>
      )}
    </User>
  );
};

export default UserInformation;
