import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import { styled as muiStyled } from '@mui/material/styles';

import styled from 'styled-components';

import { Typography } from '@hapvida/hapvida-core-components';

import { COLORS } from '../../themes/colors';
import { Avatar, Badge } from '@mui/material';

interface StyledDialogContentProps extends DialogContentProps {
  hasIcon?: boolean;
}

export const ModalWrapper = muiStyled(Dialog)({
  '& .MuiDialog-container': {},

  '& .MuiDialog-paper': {
    padding: '24px',
    borderRadius: '8px',
    maxWidth: '484px',
    minWidth: '430px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
});

export const Title = muiStyled(Typography)({
  fontFamily: 'Source Sans Pro',
  fontSize: '20px',
  fontWeight: '900',
  lineHeight: '25px',
  letterSpacing: '0em',
  color: COLORS.MONOCHROMATIC.GRAY4,
});

export const StyledDialogContent = muiStyled(
  DialogContent,
)<StyledDialogContentProps>(({ hasIcon }) => ({
  padding: hasIcon ? '1.5rem 0.5rem 0.5rem 1.5rem' : '0.5rem',
  marginLeft: hasIcon ? '1.5rem' : '0',
}));

export const Content = muiStyled(Box)({
  margin: '0.7rem 0 1.5rem 0',
});

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    padding: 0px;
    border-radius: 60px;
    border: 3px solid white;
    background: ${COLORS.PRIMARY.BLUE.MAIN};
    width: 24px;
    height: 24px;
  }
`;

export const StyledAvatar = styled(Avatar)`
  && {
    width: 112px;
    height: 112px;
    border-radius: 100px;
    border: 2px solid ${COLORS.PRIMARY.BLUE.MAIN};
  }
`;

export const PropLabel = muiStyled(Typography)({
  fontFamily: 'Source Sans Pro',
  fontWeight: 400,
  fontSize: '14px',
  color: COLORS.MONOCHROMATIC.GRAY4,
});

export const PropValue = muiStyled(Typography)({
  fontFamily: 'Source Sans Pro',
  fontWeight: 600,
  fontSize: '14px',
  color: COLORS.MONOCHROMATIC.GRAY4,
});
