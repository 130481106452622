import { FC, ReactElement, useCallback } from 'react';

import { Controller } from 'react-hook-form';

import { Radio, Grid, RadioGroup } from '@mui/material';

import Typography from '../Typography';

import {
  CardContentWrapper,
  Content,
  IconWrapper,
  Label,
  StyledStack,
  Title,
} from './styles';

type Option = {
  key: string;
  value: string | number;
  description?: string;
  icon?: ReactElement;
};

interface RadioCardProps {
  options: Option[];
  label: string;
  value?: string | number;
  onChange?(value: string): void;
  name: string;
  control?: any;
  background?: string;
  fullWidth?: boolean;
  disabledRadio?: boolean;
}

const RadioCard: FC<RadioCardProps> = ({
  label,
  options,
  value,
  onChange,
  name,
  control,
  background,
  fullWidth = false,
  disabledRadio = false,
  ...cardProps
}) => {
  const handleChange = useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value);
      }
    },
    [onChange],
  );

  const renderRadioGroup = () => {
    return (
      <RadioGroup row>
        <Grid item container spacing={2}>
          {options.map(option => {
            const isActive = value === option.value;
            return (
              <Grid item key={option.key} xs={fullWidth ? 12 : true}>
                <StyledStack
                  direction="row"
                  alignItems="center"
                  isActive={isActive}
                  background={background}
                >
                  <CardContentWrapper
                    {...cardProps}
                    onClick={
                      disabledRadio
                        ? undefined
                        : () => {
                            handleChange(option.value.toString());
                          }
                    }
                  >
                    <Content>
                      <Radio
                        checked={isActive}
                        onChange={e => handleChange(e.target.value)}
                        value={option.value}
                        disabled={disabledRadio}
                      />
                      <Label>
                        <Title active={isActive}>{option.key}</Title>
                        {option.description && (
                          <Typography fontSize={14}>
                            {option.description}
                          </Typography>
                        )}
                      </Label>
                    </Content>
                    <IconWrapper>{option.icon}</IconWrapper>
                  </CardContentWrapper>
                </StyledStack>
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    );
  };
  return (
    <Grid container>
      {label && (
        <Grid item xs={12} mb={2}>
          <Typography variant="text">{label} </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {control ? (
          <Controller
            name={name}
            control={control}
            render={({ fieldState, formState, ...props }) => {
              return renderRadioGroup();
            }}
          />
        ) : (
          renderRadioGroup()
        )}
      </Grid>
    </Grid>
  );
};

export default RadioCard;
