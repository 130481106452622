import { TableCellProps } from '@mui/material';
import React, { useCallback } from 'react';
import OrderColumn from '../../OrderColumn';
import Typography from '../../Typography';
import { TableOrder } from '../../../utils/table';
import { ColumnNameStack, StyledTableCell } from './styles';

interface TableHeadCellProps extends TableCellProps {
  label?: string;
  name?: string;
  order?: TableOrder | null;
  hide?: boolean;
  onOrderChange?(order: 'asc' | 'desc' | null, name: string): void;
}

const TableHeadCell: React.FC<TableHeadCellProps> = ({
  label,
  order,
  name,
  hide = false,
  onOrderChange,
  ...props
}) => {
  const handleOrderChange = useCallback(() => {
    if (onOrderChange && name) {
      if (name === order?.sortBy) {
        if (order.orderBy === 'asc') {
          onOrderChange('desc', name);
        }

        if (order.orderBy === 'desc') {
          onOrderChange(null, name);
        }
      } else {
        onOrderChange('asc', name);
      }
    }
  }, [onOrderChange, name, order]);

  return (
    <StyledTableCell {...props}>
      {label && (
        <ColumnNameStack
          direction="row"
          alignItems="center"
          gap={1}
          onClick={!hide ? handleOrderChange : undefined}
        >
          <Typography>{label}</Typography>
          {!hide && (
            <OrderColumn
              order={name === order?.sortBy ? order?.orderBy : null}
            />
          )}
        </ColumnNameStack>
      )}
    </StyledTableCell>
  );
};

export default TableHeadCell;
