import { ReactNode } from 'react';
import {
  useMatch,
  useNavigate,
  useResolvedPath,
  useSearchParams,
} from 'react-router-dom';
import { ICoreRoute } from '../..';
import { LinkStyled, RouteItem } from './styles';

interface RouteLinkProps {
  children: ReactNode;
  route: ICoreRoute;
  drawerOpen: boolean;
  backofficeVision: boolean;
  disabled: boolean;
}

const RouteLink = ({
  children,
  route,
  drawerOpen,
  backofficeVision,
  disabled,
}: RouteLinkProps) => {
  const navigate = useNavigate();
  let resolved = useResolvedPath({ pathname: route.path });
  let match = useMatch({ path: resolved.pathname, end: false });

  const [searchParams, setSearchParams] = useSearchParams();

  const currentRoute = window.location.pathname;

  searchParams.get('search');

  return (
    <LinkStyled to={route.path} disabled={disabled}>
      <RouteItem
        key={route.path}
        direction={drawerOpen ? 'row' : 'column'}
        alignItems="center"
        justifyContent={drawerOpen ? 'flex-start' : 'center'}
        open={drawerOpen}
        selected={!!match}
        gap={1}
        onClick={() => {
          if (match?.pathname === currentRoute) {
            setSearchParams('');
            window.location.reload();
          }
          !!disabled && navigate(route.path);
        }}
        backofficeVision={backofficeVision}
        disabled={disabled}
      >
        {children}
      </RouteItem>
    </LinkStyled>
  );
};

export default RouteLink;
