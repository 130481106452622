import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrderColumn from '../../../../OrderColumn';
import { LineColumn } from '../../../types';
import { Container, TextSelectedOrder } from './styles';

interface ColumnLineItemProps {
  item: LineColumn;
  columnKey: string;
  order?: {
    sortBy?: string | null;
    orderBy?: 'asc' | 'desc' | null;
  } | null;
  onOrderChange(name: string): void;
}

const ColumnLineItem: React.FC<ColumnLineItemProps> = ({
  item,
  columnKey,
  order,
  onOrderChange,
}) => {
  const [params] = useSearchParams();
  const [sortBy] = useMemo(() => [params.get('sortBy')], [params]);

  const orderSelect = (sort: string | null) => {
    if (sort === 'status') {
      return (
        <>
          <Typography>{item.name}</Typography>
          <TextSelectedOrder>{item.status}</TextSelectedOrder>
        </>
      );
    } else if (sort === 'name') {
      return (
        <>
          <TextSelectedOrder>{item.name}</TextSelectedOrder>
          <Typography>{item.status}</Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography>{item.name}</Typography>
          <Typography>{item.status}</Typography>
        </>
      );
    }
  };

  return (
    <Grid item xs={item.size} className="ListItem">
      {!item.hide && (
        <Container
          direction="row"
          alignItems="center"
          gap={1}
          onClick={() => onOrderChange(columnKey)}
        >
          {orderSelect(sortBy)}

          <OrderColumn
            order={columnKey === order?.sortBy ? order?.orderBy : null}
          />
        </Container>
      )}
    </Grid>
  );
};

export default ColumnLineItem;
