import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';

export type SummaryItens = {
  label: string;
  color?: string;
  key: string;
};

export const GRAPHIC_SUMMARY_DATA: SummaryItens[] = [
  {
    label: 'Titulares',
    key: 'holders',
    color: COLORS.PRIMARY.BLUE.MAIN,
  },
  {
    label: 'Dependentes',
    key: 'dependents',
    color: COLORS.PRIMARY.ORANGE.MAIN,
  },
  {
    label: 'Agregados',
    key: 'aggregates',
    color: COLORS.PRIMARY.BLUE['-3'],
  },
  {
    label: 'Demitidos',
    key: 'fired',
    color: COLORS.MONOCHROMATIC.GRAY4,
  },
  {
    label: 'Aposentados',
    key: 'retirees',
    color: COLORS.DISABLED.BACKGROUND,
  },
];
