import { FC, useState } from 'react';

import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import { COLORS } from '../../themes/colors';

import Icon from '../Icon';
import { IconName } from '../Icon/types';

import { Title, Content, Wrapper, Subtitle } from './styles';

type Variant = 'success' | 'warning' | 'danger' | 'info';

interface CollapseCardProps {
  title: string;
  subtitle?: string;
  iconName?: IconName;
  iconColor?: string;
  variant: Variant;
  insideCollapse?: boolean;
}

const IconColors: { [color: string]: string } = {
  success: COLORS.SECONDARY.GREEN['+1'],
  warning: COLORS.PRIMARY.ORANGE['+1'],
  danger: COLORS.SECONDARY.RED['+1'],
  info: COLORS.PRIMARY.BLUE['+1'],
};

const CollapseCard: FC<CollapseCardProps> = ({
  title,
  subtitle,
  iconName,
  children,
  variant,
  insideCollapse = true,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper direction="column" variant={variant}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          <Stack justifyContent="flex-start">
            <Icon
              name={iconName ? iconName : 'alert'}
              color={IconColors[variant]}
              size={30}
            />
          </Stack>

          <Stack direction="column" justifyContent="flex-start">
            <Title variant={variant}>{title}</Title>
            {subtitle && <Subtitle variant={variant}>{subtitle}</Subtitle>}
          </Stack>
        </Stack>
        {insideCollapse && (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <Icon
              name={open ? 'chevron-up' : 'chevron-down'}
              color={COLORS.MONOCHROMATIC.GRAY4}
              size={24}
            />
          </IconButton>
        )}
      </Stack>
      <Content>
        {insideCollapse ? (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ paddingTop: '1rem' }}
          >
            {children}
          </Collapse>
        ) : (
          <Box sx={{ paddingTop: '1rem' }}>children</Box>
        )}
      </Content>
    </Wrapper>
  );
};

export default CollapseCard;
