import { styled as muiStyled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';

import { COLORS } from '../../themes/colors';

type Variant = 'success' | 'warning' | 'danger' | 'info';

interface WrapperProps {
  variant: Variant;
}

interface TitleProps {
  variant: Variant;
}

interface SubtitleProps {
  variant: Variant;
}

export const Wrapper = muiStyled(Stack)<WrapperProps>(({ variant }) => ({
  padding: '24px',
  borderRadius: '8px',

  border:
    variant === 'success'
      ? `1px solid ${COLORS.SECONDARY.GREEN['+1']}`
      : variant === 'warning'
      ? `1px solid ${COLORS.PRIMARY.ORANGE['+1']}`
      : variant === 'danger'
      ? `1px solid ${COLORS.SECONDARY.RED['+1']}`
      : variant === 'info'
      ? `1px solid ${COLORS.PRIMARY.BLUE['+1']}`
      : '',

  background:
    variant === 'success'
      ? 'linear-gradient(0deg, rgba(82, 135, 44, 0.04), rgba(82, 135, 44, 0.04)), #FFFFFF'
      : variant === 'warning'
      ? 'linear-gradient(0deg, rgba(244, 121, 32, 0.04), rgba(244, 121, 32, 0.04)), #FFFFFF;'
      : variant === 'danger'
      ? 'rgba(201, 54, 0, 0.04)'
      : variant === 'info'
      ? 'rgba(0, 84, 184, 0.04);'
      : '',

  boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.06)',
}));

export const Title = muiStyled('h6')<TitleProps>(({ variant }) => ({
  fontStyle: 'normal',
  fontWeight: 900,
  fontSize: '16px',
  lineHeight: '1.75',
  margin: 0,

  color:
    variant === 'success'
      ? COLORS.SECONDARY.GREEN['+1']
      : variant === 'warning'
      ? COLORS.PRIMARY.ORANGE['+1']
      : variant === 'danger'
      ? COLORS.SECONDARY.RED['+1']
      : variant === 'info'
      ? COLORS.PRIMARY.BLUE['+1']
      : '',
}));

export const Subtitle = muiStyled('p')<SubtitleProps>(({ variant }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '8px',

  color: COLORS.MONOCHROMATIC.GRAY4,
}));

export const Content = muiStyled(Stack)({
  paddingLeft: '3.5rem',
});
