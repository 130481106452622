import { Grid, Stack, Typography } from '@mui/material';
import {
  QuotationStatusEnum,
  quotationEnumKeys,
} from '../../constants/enumsUnified';
import formatDateISO from '../../utils/formatDateISO';
import Icon from '../Icon';
import { Container, StatusTitle } from './styles';

interface QuotationStatusProps {
  status: QuotationStatusEnum;
  date?: string;
}

const QuotationStatusCard = ({ status, date }: QuotationStatusProps) => {
  return (
    <Container
      item
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background: quotationEnumKeys[status]?.color,
        border: `1px solid ${quotationEnumKeys[status]?.color}`,
      }}
    >
      <Stack alignItems="center" direction="row" gap={2}>
        <Icon name={quotationEnumKeys[status]?.icon} color="white" size={24} />
        <Grid
          item
          sx={{
            background: quotationEnumKeys[status]?.color,
          }}
        >
          <StatusTitle color="white">
            {quotationEnumKeys[status]?.name}
          </StatusTitle>
          {date && status !== QuotationStatusEnum.InEdition ? (
            <Typography color="white" fontSize={12}>
              Iniciado em{' '}
              <strong>
                {formatDateISO(date, "dd 'de' MMMM 'de' yyyy 'às' HH:mm")}
              </strong>
            </Typography>
          ) : null}
        </Grid>
      </Stack>
    </Container>
  );
};

export default QuotationStatusCard;
