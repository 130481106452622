import React from 'react';
import { ButtonProps, Stack } from '@mui/material';

import { MuiButton } from './styles';

interface Props extends ButtonProps {
  fontColor?: string;
  rightComponent?: JSX.Element;
}

const Button: React.FC<Props> = ({
  children,
  rightComponent,
  fontColor,
  ...props
}) => {
  return (
    <MuiButton style={{ color: fontColor }} {...props}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        {children}
        {rightComponent}
      </Stack>
    </MuiButton>
  );
};

export default Button;
