import { styled } from '@mui/system';

interface OverflowStripProps {
  color?: string;
}

export const OverflowStrip = styled('div', {
  shouldForwardProp: prop => prop !== 'color',
})<OverflowStripProps>(({ color = '#0054B814' }) => ({
  background: color,
  boxShadow: `0 0 0 100vmax ${color}`,
  clipPath: 'inset(0 -100vmax)',
}));
