import React from 'react';
import Typography from '../Typography';
import { Container } from './styles';

interface AutoCompleteItemProps extends React.HTMLAttributes<HTMLLIElement> {
  title: string;
  subtitle?: string;
}

const AutoCompleteItem: React.FC<AutoCompleteItemProps> = ({
  title,
  subtitle,
  ...props
}) => {
  return (
    <Container {...props}>
      <Typography variant="subtitle1">{title}</Typography>
      {subtitle && <Typography variant="smallText">{subtitle}</Typography>}
    </Container>
  );
};

export default AutoCompleteItem;
