import { Stack } from '@mui/material';
import React from 'react';

interface TableContainerProps {
  totalItems: number;
  emptyState?: JSX.Element;
  loading?: boolean;
}

const TableContainer: React.FC<TableContainerProps> = ({
  totalItems,
  emptyState,
  loading,
  children,
}) => {
  return loading ? (
    <></>
  ) : (
    <Stack>
      {totalItems !== 0 ? <>{children}</> : <>{emptyState}</>}
    </Stack>
  );
};

export default TableContainer;
