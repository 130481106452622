import React, { useCallback, useState } from 'react';

import { Controller } from 'react-hook-form';

import { Grid, Stack } from '@mui/material';

import Icon from '../Icon';
import Checkbox from '../Checkbox';
import Typography from '../Typography';

import { CardContainer, TitleDescription, BodyDescription } from './styles';
import { IconName } from '../Icon/types';
import { COLORS } from '../../themes/colors';

interface CheckBoxCardProps {
  label?: string;
  options?: Array<{
    name: string;
    title: string;
    subtitle?: string;
    icon: IconName;
    color?: string;
    checked: 'true' | 'false';
    disabled?: boolean;
    xs?: number;
  }>;
  control: any;
  onChange?(values: { [x: string]: 'true' | 'false' }): void;
  useBreakpoint?: boolean;
}

const CheckBoxCard: React.FC<CheckBoxCardProps> = ({
  label,
  onChange,
  options,
  control,
  useBreakpoint = false,
}) => {
  const [optionsSelected, setOptionsSelected] = useState<{
    [x: string]: 'true' | 'false';
  }>(() => {
    let mappedOptions: { [x: string]: 'true' | 'false' } = {};
    if (options) {
      options?.forEach(el => {
        mappedOptions[el.name] = el.checked;
      });
    }
    return mappedOptions;
  });

  const handleChange = useCallback(
    (name: string) => {
      const mappedValue = { ...optionsSelected };
      mappedValue[name] = mappedValue[name] === 'true' ? 'false' : 'true';

      setOptionsSelected(mappedValue);

      if (onChange) {
        onChange(mappedValue);
      }
    },
    [onChange, optionsSelected],
  );

  return (
    <Stack gap={1}>
      {label && <Typography variant="text">{label}:</Typography>}
      <Grid container={useBreakpoint} gap={2} display="flex">
        {options?.map(option => (
          <CardContainer
            xs={option.xs}
            item
            container
            key={option.name}
            checked={option.checked === 'true'}
            alignItems="center"
            onClick={() => handleChange(option.name)}
          >
            <Grid item xs={11}>
              <Stack direction="row">
                <Controller
                  name={option.name}
                  control={control}
                  render={({ field: { ref, ...props } }) => (
                    <Checkbox
                      {...props}
                      checked={option.checked === 'true'}
                      disabled={option.disabled}
                      onChange={() => handleChange(option.name)}
                    />
                  )}
                />
                <Stack justifyContent={option.subtitle ? 'normal' : 'center'}>
                  <TitleDescription>{option.title}</TitleDescription>
                  {option.subtitle && (
                    <BodyDescription>{option.subtitle}</BodyDescription>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item container xs={1} justifyContent="flex-end">
              <Icon
                name={option.icon}
                size={24}
                color={option?.color ?? COLORS.PRIMARY.ORANGE.MAIN}
              />
            </Grid>
          </CardContainer>
        ))}
      </Grid>
    </Stack>
  );
};

export default CheckBoxCard;
