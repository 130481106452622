import { Grid } from '@mui/material';
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ListOnScrollProps,
  VariableSizeList as DynamicVirtualList,
} from 'react-window';
import {
  AgencyStatusWithAlert,
  BackOfficeStatusWithAlert,
  QuotationStatusEnum,
} from '../../../constants/enums';
import { UserType } from '../../layouts/DrawerLayout';

import { LineColumn } from '../types';
import ColumnLineItem from './components/ColumnLineItem';

interface LineViewListProps<T = any> {
  data?: Array<T>;
  columns: {
    [x: string]: LineColumn;
  };
  order?: {
    sortBy?: string | null;
    orderBy?: 'asc' | 'desc' | null;
  } | null;
  hasNextPage?: boolean;
  isNextPageLoading?: boolean;
  itemHeight?: number;

  loadNextPage: () => Promise<void>;
  onOrderChange?(order: 'asc' | 'desc', name: string): void;
  renderRow(data: T, style: any): any;
  listContainerSize?: number | undefined;
  currentUserType: UserType | undefined;
  listContainerStyle?: any;
}

const LineViewList: React.FC<LineViewListProps> = ({
  data = [],
  columns,
  order,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  onOrderChange,
  renderRow,
  listContainerSize,
  currentUserType,
  listContainerStyle,
}) => {
  const dataArraySize = useRef(0);
  const virtualizedList = useRef({} as DynamicVirtualList);
  const [heightsArray, setHeightsArray] = useState<number[]>([0]);

  const statusWithAlert = useMemo(
    () =>
      currentUserType === UserType.Company ||
      currentUserType === UserType.Agency
        ? AgencyStatusWithAlert
        : BackOfficeStatusWithAlert,
    [currentUserType],
  );

  useLayoutEffect(() => {
    const updatedHeightValues = data.map(
      (quotation: {
        number: string;
        legalName?: string;
        document?: string;
        thumbnail?: string;
        createdAt: string;
        status: QuotationStatusEnum;
      }): number => {
        return (
          (statusWithAlert[quotation.status] !== undefined ? 124 : 92) + 16
        );
      },
    );

    setHeightsArray(updatedHeightValues);
    if (virtualizedList.current) {
      virtualizedList.current.resetAfterIndex(0);
    }
    dataArraySize.current = data.length;
  }, [data, statusWithAlert]);

  const handleOrderChange = useCallback(
    (name: string) => {
      if (onOrderChange && name) {
        if (name === order?.sortBy) {
          if (order.orderBy === 'asc') {
            onOrderChange('desc', name);
          }

          if (order.orderBy === 'desc') {
            onOrderChange('asc', name);
          }
        } else {
          onOrderChange('asc', name);
        }
      }
    },
    [onOrderChange, order],
  );

  const renderColumns = useCallback(() => {
    const keys = Object.keys(columns);

    return keys.map(key => (
      <ColumnLineItem
        key={key}
        columnKey={key}
        item={columns[key]}
        onOrderChange={handleOrderChange}
        order={order}
      />
    ));
  }, [columns, handleOrderChange, order]);

  const handleListScroll = useCallback(
    async ({ scrollOffset }: ListOnScrollProps) => {
      if (!heightsArray) return;

      const heightsSum = heightsArray.reduce((prev, value) => {
        return prev + value;
      }, 0);

      const screenHeight = listContainerSize || 500;

      if (
        hasNextPage &&
        !isNextPageLoading &&
        scrollOffset + screenHeight >= heightsSum * 0.99
      ) {
        await loadNextPage();
      }
    },
    [
      hasNextPage,
      heightsArray,
      isNextPageLoading,
      listContainerSize,
      loadNextPage,
    ],
  );

  const getItemSize = useCallback(
    (index: number) => {
      return (
        (statusWithAlert[data[index].status as QuotationStatusEnum] !==
        undefined
          ? 124
          : 92) + 16
      );
    },
    [data, statusWithAlert],
  );

  return (
    <Grid container>
      <Grid container item xs={12}>
        {renderColumns()}
      </Grid>
      <Grid container item xs={12}>
        <DynamicVirtualList
          className="List"
          height={listContainerSize || 500}
          width={'100%'}
          itemCount={data.length}
          itemSize={getItemSize}
          onScroll={handleListScroll}
          overscanCount={10}
          style={listContainerStyle}
          ref={virtualizedList}
        >
          {({ index, style }) =>
            renderRow(data[index], {
              ...style,
              width: '99%',
            })
          }
        </DynamicVirtualList>
      </Grid>
    </Grid>
  );
};

export default LineViewList;
