import { isValid } from 'date-fns';
import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Filter, FiltersGroup, useDrawerFilter } from './useDrawerFilter';

const DATE_FILTER_KEYS = {
  startDate: 'start-date',
  endDate: 'end-date',
};

export function useFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { activeFilters, setActiveFilters, initialFilters, cleanFilters } =
    useDrawerFilter();

  const hasDiff = (
    urlParams: URLSearchParams,
    newParams: Record<string, string>,
  ) => {
    const convertedParams = Object.fromEntries(urlParams.entries());
    return !_.isEqual(convertedParams, newParams);
  };
}
