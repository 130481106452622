import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../Icon';
import { FilterButton } from './styles';
import { COLORS } from '../../themes/colors';
import { useMemo } from 'react';
import {
  Filter,
  FiltersGroup,
  useDrawerFilter,
} from '../../hooks/useDrawerFilter';
import { format } from 'date-fns';
import { Gender } from '../../constants/enums';

interface CustomFilter extends Filter {
  filterKey: string;
  isGroup?: boolean;
}

interface FiltersListProps {
  onApplyFilters?: (filters: FiltersGroup) => void;
}

const FiltersList = ({ onApplyFilters }: FiltersListProps) => {
  const { activeFilters, handleFilterChange, ignoreFilters } =
    useDrawerFilter();

  const currentFiltersGroups: CustomFilter[] = useMemo(() => {
    const filters: CustomFilter[] = [];

    Object.keys(activeFilters).forEach(key => {
      if (ignoreFilters.includes(key)) return;

      if (key !== 'date') {
        if (activeFilters[key].filters!.length > 1) {
          const currentGroup = activeFilters[key];

          const filterCount = currentGroup.filters!.length;

          const filterLabel =
            filterCount > 1 ? currentGroup.label : currentGroup.singularLabel;

          const labelOrganizational =
            key === 'organization-groups'
              ? 'grupos organizacionais'
              : 'filtros';

          const labelFilterUndefined = filterLabel ?? labelOrganizational;

          const filterNounGender =
            currentGroup.gender === Gender.Feminine
              ? 'selecionadas'
              : 'selecionados';

          filters.push({
            isGroup: true,
            filterKey: key,
            content: `${currentGroup.label}-group`,
            label: `${filterCount} ${labelFilterUndefined} ${filterNounGender}`,
            value: currentGroup.label!,
          });
        } else if (activeFilters[key].filters!.length === 1) {
          filters.push({
            ...activeFilters[key].filters![0],
            filterKey: key,
          });
        }
      } else {
        const dateGroup = activeFilters[key];

        if (dateGroup.startDate && dateGroup.endDate) {
          filters.push({
            filterKey: key,
            content: 'date',
            label: `Início: ${format(
              activeFilters[key].startDate!,
              'dd/MM/yyyy',
            )} • Término: ${format(activeFilters[key].endDate!, 'dd/MM/yyyy')}`,
            value: 'date',
          });
        }
      }
    });

    return filters;
  }, [activeFilters, ignoreFilters]);

  const handleChange = (filter: CustomFilter) => {
    let newActiveFilters: FiltersGroup;

    if (filter.filterKey !== 'date') {
      newActiveFilters = Object.fromEntries(
        Object.entries(activeFilters).filter(
          ([key]) => key !== filter.filterKey,
        ),
      ) as FiltersGroup;
    } else {
      const { date, ...otherFilters } = activeFilters;
      newActiveFilters = otherFilters as FiltersGroup;
    }

    handleFilterChange(newActiveFilters);
    onApplyFilters?.(newActiveFilters);
  };
  return (
    <Box flexWrap="wrap" display="flex">
      {currentFiltersGroups.map(fullFilter => (
        <FilterButton mt={1} key={fullFilter.label ?? fullFilter.value}>
          <Typography>{fullFilter.label}</Typography>
          <Icon
            onClick={e => {
              e.stopPropagation();
              handleChange(fullFilter);
            }}
            name="close"
            size={16}
            color={COLORS.WHITE}
          />
        </FilterButton>
      ))}
    </Box>
  );
};

export default FiltersList;
