import * as mime from 'mime-types';
import IDragAndDropFile from '../components/DragAndDrop/dtos/IDragDropFile';
import { removeCaracterSpecial } from './removeCaracterSpecial';

export class Base64File {
  constructor(
    public name: string,
    public data: string,
    public size: number | string,
  ) {}

  get url(): string {
    return `${this.data}`;
  }

  get extension(): string | false {
    return false;
  }

  static fromFile(file: File): Promise<IDragAndDropFile> {
    return new Promise((res, rej) => {
      const reader = new FileReader();

      reader.onload = () => {
        const fileResult = reader.result?.toString();

        const path = fileResult;
        res({
          date: new Date().toISOString(),
          fileName: removeCaracterSpecial(file.name),
          path: path || '',
          size: file.size,
        });
      };

      reader.onerror = error => rej(error);

      reader.readAsDataURL(file);
    });
  }
}
