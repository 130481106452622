import React, { useContext, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { parseISO } from 'date-fns';
import {
  DrawerFilterContext,
  DrawerFilterContextData,
  InitialFiltersConfig,
  LabelNamingConfig,
} from '../contexts/DrawerFilterContext';
import { Gender } from '../constants/enums';

export interface Filter {
  content: string | JSX.Element;
  label: string;
  value: string;
}

export interface NormalGroup {
  label: string;
  filters: Filter[];
  singularLabel?: string;
  gender?: Gender;
  hasMore?: boolean;
  isDynamic?: boolean;
}

export interface DateGroup {
  startDate: Date | null;
  endDate: Date | null;
}

type StringLiteralUnion<T extends U, U = string> = T | (U & {});

export type FiltersGroup = {
  [K in StringLiteralUnion<'date'>]: K extends 'date'
    ? DateGroup
    : Partial<DateGroup> &
        Partial<NormalGroup> & {
          search?: string;
        };
};

export interface DrawerFilterProviderProps {
  ignoreFilters?: string[];
}

const DrawerFilterProvider: React.FC<DrawerFilterProviderProps> = ({
  children,
  ignoreFilters = [],
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const [activeFilters, setActiveFilters] = useState<any>({});
  const [provFilters, setProvFilters] = useState<FiltersGroup>(
    {} as FiltersGroup,
  );
  const [initialFilters, setInitialFilters] = useState<FiltersGroup>(
    {} as FiltersGroup,
  );

  const [filterKeys, setFilterKeys] = useState<string[]>([]);

  const blacklist: string[] = useMemo(
    () => [...ignoreFilters, 'search', 'orderBy', 'sortBy'],
    [ignoreFilters],
  );

  const filterCount = useMemo(() => {
    let count = 0;
    Object.keys(activeFilters).forEach(key => {
      if (key === 'date') {
        if (activeFilters[key].startDate || activeFilters[key].endDate) {
          count++;
        }
      } else {
        if (!blacklist.includes(key)) {
          count += activeFilters[key].filters!.length;
        }
      }
    });

    return count;
  }, [activeFilters, blacklist]);

  const parseFilterToQueryParams = (filters: FiltersGroup) => {
    let queryParams: { [key: string]: string | string[] } = {};

    const filtersKey = Object.keys(filters);

    filtersKey.forEach(filterKey => {
      const filter = filters[filterKey];

      if (filter.filters) {
        queryParams = {
          ...queryParams,
          [filterKey]: filter.filters?.map(el => el.value),
        };
      }

      if (filter.startDate) {
        queryParams = {
          ...queryParams,
          'start-date': filter.startDate.toISOString(),
        };
      }

      if (filter.endDate) {
        queryParams = {
          ...queryParams,
          'end-date': filter.endDate.toISOString(),
        };
      }
    });

    searchParams.forEach((value, key) => {
      if (blacklist.includes(key)) {
        queryParams[key] = value;
      }
    });

    setSearchParams(queryParams);
  };

  const cleanFilters = () => {
    if (window.location.pathname === pathname) {
      searchParams.forEach((_, key) => {
        if (filterKeys.includes(key)) {
          searchParams.delete(key);
        }
      });

      setSearchParams(searchParams);
    }

    setActiveFilters({} as FiltersGroup);
    setProvFilters({} as FiltersGroup);
    setInitialFilters({} as FiltersGroup);
  };

  const applyFilters = (cb?: Function) => {
    setActiveFilters(provFilters);
    parseFilterToQueryParams(provFilters);
    if (cb) {
      cb(provFilters);
    }
  };

  const getInitialFilter = (
    initialFilter: InitialFiltersConfig,
    labelNaming?: LabelNamingConfig,
  ) => {
    let filters: FiltersGroup = {
      date: {
        startDate: null,
        endDate: null,
      },
    };
    searchParams.forEach((value, key) => {
      if (key === 'start-date') {
        setActiveFilters((prev: any) => ({
          ...prev,
          date: {
            ...prev.date,
            startDate: parseISO(value),
          },
        }));
        filters = {
          ...filters,
          date: {
            ...filters.date,
            startDate: parseISO(value),
          },
        };
        return;
      }

      if (key === 'end-date') {
        setActiveFilters((prev: any) => ({
          ...prev,
          date: {
            ...prev.date,
            endDate: parseISO(value),
          },
        }));
        filters = {
          ...filters,
          date: {
            ...filters.date,
            endDate: parseISO(value),
          },
        };
        return;
      }

      const labelConfig: Partial<
        Pick<NormalGroup, 'label' | 'singularLabel' | 'gender'>
      > = {};

      const filterNaming = labelNaming?.[key];
      if (typeof filterNaming === 'string') {
        labelConfig.label = filterNaming;
      } else if (typeof filterNaming === 'object' && filterNaming !== null) {
        labelConfig.label = filterNaming.plural;
        labelConfig.singularLabel = filterNaming.singular;
        labelConfig.gender = filterNaming.gender;
      }

      setActiveFilters((prev: any) => ({
        ...prev,
        [key]: {
          ...prev[key],
          ...labelConfig,
          filters: [
            ...(prev[key]?.filters ?? []),
            {
              content: '',
              label: initialFilter[key]?.find(el => el.value === value)?.label,
              value,
            },
          ],
        },
      }));
      filters = {
        ...filters,
        [key]: {
          ...labelConfig,
          filters: [
            ...(filters[key]?.filters ?? []),
            {
              content: '',
              label:
                initialFilter[key]?.find(el => el.value === value)?.label ?? '',
              value,
            },
          ],
        },
      };
    });

    return filters;
  };

  const handleFilterChange = (filter: FiltersGroup) => {
    let queryParams = Object.entries(filter).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        if (key === 'date' && value.startDate && value.endDate) {
          acc['start-date'] = value.startDate.toISOString();
          acc['end-date'] = value.endDate.toISOString();
        } else {
          acc[key] = (value as NormalGroup)?.filters
            ?.map(filter => filter.value)
            .join(',');
        }
        return acc;
      },
      {},
    );
    queryParams = Object.entries(queryParams).reduce<any>(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {},
    );

    searchParams.forEach((value, key) => {
      if (blacklist.includes(key)) {
        queryParams[key] = value;
      }
    });

    setSearchParams(queryParams);
    setActiveFilters(filter);
  };

  const register = (filterKey: string) => {
    setFilterKeys(prev => [...prev, filterKey]);
  };

  const unregister = (filterKey: string) => {
    setFilterKeys(prev => prev.filter(key => key !== filterKey));
  };

  return (
    <DrawerFilterContext.Provider
      value={{
        activeFilters,
        setActiveFilters,
        provFilters,
        setProvFilters,
        cleanFilters,
        applyFilters,
        initialFilters,
        setInitialFilters,
        filterCount,
        getInitialFilter,
        handleFilterChange,
        ignoreFilters: blacklist,
        register,
        unregister,
      }}
    >
      {children}
    </DrawerFilterContext.Provider>
  );
};

function useDrawerFilter(): DrawerFilterContextData {
  const context = useContext(DrawerFilterContext);

  if (!context) {
    throw new Error('useDrawerFilter must be used within DrawerFilterProvider');
  }

  return context;
}

export { DrawerFilterProvider, useDrawerFilter };
