import { Stack, Typography } from '@mui/material';
import { COLORS } from '../../themes/colors';
import Icon from '../Icon';

export const SupportContact = () => {
  return (
    <Stack gap={2} paddingBottom={3}>
      <Typography color={COLORS.BLUE.DARK} variant="text">
        Ou fale conosco por uma das formas de contato abaixo:
      </Typography>
      <Stack direction="row" gap={3}>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon
            name="mail"
            variant="filled"
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
          <Typography variant="text" color={COLORS.MONOCHROMATIC.GRAY4}>
            comercial@hapvidagndi.com.br
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
