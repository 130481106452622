import { createTheme } from '@mui/material/styles';

import chroma from 'chroma-js';
import { COLORS } from './colors';
import MuiAutocompleteTheme from './components/MuiAutoCompleteTheme';
import MuiAvatarTheme from './components/MuiAvatarTheme';
import MuiButtonTheme from './components/MuiButtonTheme';
import MuiCheckboxTheme from './components/MuiCheckboxTheme';
import MuiFilledInputTheme from './components/MuiFilledInputTheme';
import MuiFormHelperTextTheme from './components/MuiFormHelperTextTheme';
import MuiInputLabelTheme from './components/MuiInputLabelTheme';
import MuiRadioTheme from './components/MuiRadioTheme';
import MuiTableRowTheme from './components/MuiTableRowTheme';
import MuiTypographyTheme from './components/MuiTypographyTheme';

const classicTheme = createTheme({
  components: {
    MuiButton: MuiButtonTheme,
    MuiTypography: MuiTypographyTheme,
    MuiFormHelperText: MuiFormHelperTextTheme,
    MuiInputLabel: MuiInputLabelTheme,
    MuiFilledInput: MuiFilledInputTheme,
    MuiCheckbox: MuiCheckboxTheme,
    MuiAvatar: MuiAvatarTheme,
    MuiTableRow: MuiTableRowTheme,
    MuiRadio: MuiRadioTheme,
    MuiAutocomplete: MuiAutocompleteTheme,
  },
  palette: {
    common: {},
    success: {
      main: COLORS.SECONDARY.GREEN.MAIN,
      dark: chroma(COLORS.SECONDARY.GREEN.MAIN).darken(0.08).hex(),
    },
    secondary: {
      main: COLORS.PRIMARY.BLUE.MAIN,
      dark: chroma(COLORS.PRIMARY.BLUE.MAIN).darken(0.08).hex(),
    },
    warning: {
      main: COLORS.SECONDARY.YELLOW.MAIN,
      dark: chroma(COLORS.SECONDARY.YELLOW.MAIN).darken(0.08).hex(),
    },
    error: {
      main: COLORS.SECONDARY.RED.MAIN,
      dark: chroma(COLORS.SECONDARY.RED.MAIN).darken(0.08).hex(),
    },
    tertiary: {
      main: COLORS.PRIMARY.ORANGE.MAIN,
      dark: chroma(COLORS.PRIMARY.ORANGE.MAIN).darken(0.08).hex(),
    },
  },
  breakpoints: {
    values: {
      xl: 1440,
      lg: 992,
      md: 768,
      sm: 576,
      xs: 0,
    },
  },

  typography: {
    allVariants: {
      fontFamily: 'Source Sans Pro',
    },
  },
});

export default classicTheme;
