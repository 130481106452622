import { Icon } from '@hapvida/hapvida-core-components';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Collapse, Stack, TextField, Typography } from '@mui/material';
import { CalendarPickerView, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addYears, format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { StyledDate, StyledStack } from './styles';

interface ContractDatePickerProps {
  date: Date | null;
  onChange: Dispatch<SetStateAction<Date | null>>;
  disabled?: boolean;
}

export const ContractDatePicker = ({
  date,
  onChange,
  disabled,
}: ContractDatePickerProps) => {
  const [picker, setPicker] = useState(false);
  const [currentView, setCurrentView] = useState<CalendarPickerView>('day');

  const minDate = useMemo(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() + 1);
  }, []);

  const maxDate = (quantityOfYears: number) => {
    return addYears(minDate, quantityOfYears);
  };

  const disableYears = (year: Date, quantityOfYears: number) => {
    return year >= maxDate(quantityOfYears);
  };

  const handleChange = (value: Date | null) => {
    onChange(value);
    if (currentView === 'day') {
      setPicker(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBrLocale}>
      <Stack>
        <StyledDate onClick={() => setPicker(!picker)}>
          <Typography>
            {date
              ? format(date, "dd 'de' LLLL 'de' yyyy", { locale: ptBrLocale })
              : ''}
          </Typography>
          {!disabled && (
            <Icon name="calendar" color={COLORS.PRIMARY.BLUE.MAIN} size={24} />
          )}
        </StyledDate>
        {!disabled && (
          <Collapse in={picker}>
            <StyledStack view={currentView}>
              <StaticDatePicker
                views={['year', 'month', 'day']}
                minDate={minDate}
                value={date}
                maxDate={maxDate(5)}
                shouldDisableYear={year => disableYears(year, 3)}
                onViewChange={setCurrentView}
                onChange={handleChange}
                renderInput={params => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </StyledStack>
          </Collapse>
        )}
      </Stack>
    </LocalizationProvider>
  );
};
