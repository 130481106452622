import React from 'react';

import { Modal } from '@mui/material';
import { LoadingContainer, StyledSpinner } from './styles';

interface ScreenLoadingProps {
  open: boolean;
  customLoading?: JSX.Element;
}

const ScreenLoading: React.FC<ScreenLoadingProps> = ({
  open,
  customLoading,
  children,
}) => {
  return (
    <>
      <Modal open={open}>
        <LoadingContainer>
          {customLoading ? customLoading : <StyledSpinner size={40} />}
        </LoadingContainer>
      </Modal>
      {children}
    </>
  );
};

export default ScreenLoading;
