import { Box, CircularProgress, styled as muiStyled } from '@mui/material';

export const LoadingContainer = muiStyled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  outline: 'none',
}));

export const StyledSpinner = muiStyled(CircularProgress)(() => ({
  outline: 'none',
  color: 'white',
  height: 60,
  width: 60,
}));
