import { FC } from 'react';

import { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { Button } from '@hapvida/hapvida-core-components';

import { ModalWrapper, Title, StyledIcon } from './styles';
import { DialogContent, Stack } from '@mui/material';
import { IconName } from '../Icon/types';

interface ModalProps extends DialogProps {
  type?: 'default' | 'alert' | 'success' | 'gray' | 'redirect';
  icon?: IconName;
  title: string;
  open: boolean;
  subtitle?: string;
  mainButtonText?: string;
  secondaryButtonText?: string;
  mainAction?: () => void;
  handleCloseModal: () => void;
  disableMainAction?: boolean;
  colorButton?:
    | 'error'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'tertiary';
}

const Modal: FC<ModalProps> = ({
  icon,
  open,
  title,
  subtitle,
  mainButtonText,
  secondaryButtonText,
  handleCloseModal,
  mainAction,
  disableMainAction,
  children,
  type = 'default',
  colorButton = 'secondary',
  ...rest
}) => {
  return (
    <ModalWrapper
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...rest}
    >
      <DialogTitle id="dialog-title">
        {icon && <StyledIcon name={icon} size={32} type={type} />}
        <Stack>
          <Title type={type}>{title}</Title>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          paddingLeft: '56px',
        }}
      >
        {children}
      </DialogContent>

      <DialogActions>
        {secondaryButtonText && (
          <Button variant="outlined" onClick={handleCloseModal}>
            {secondaryButtonText}
          </Button>
        )}
        {mainAction && mainButtonText ? (
          <Button
            variant="contained"
            color={colorButton}
            onClick={mainAction}
            disabled={disableMainAction}
          >
            {mainButtonText}
          </Button>
        ) : null}
      </DialogActions>
    </ModalWrapper>
  );
};

export default Modal;
