import { useRef, useState } from 'react';
import { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Stack } from '@mui/material';
import resizeBase64Image from '../../utils/base64compress';
import { formatBytes } from '../../utils/formatBytes';
import { PropLabel, PropValue } from '../AvatarPickerUser/styles';
import Button from '../Button';
import IDragAndDropFile from '../DragAndDrop/dtos/IDragDropFile';
import { canvasPreview } from './canvasPreview';
import { CanvasPreview, CropContainer, CropImage } from './style';
interface ImageCropProps {
  image: IDragAndDropFile;
  setBase64: React.Dispatch<React.SetStateAction<string>>;
  maxSize: number;
  acceptedTypes: string | string[];
  handleCloseModal: () => void;
  handleSendImage: () => void;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}
const ImageCrop = ({
  image,
  setBase64,
  maxSize,
  acceptedTypes,
  handleCloseModal,
  handleSendImage,
}: ImageCropProps) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [cropState, setCropState] = useState<Crop>();
  const [completedCropState, setCompletedCropState] = useState<PixelCrop>();
  const [aspectState] = useState<number | undefined>(5 / 5);
  const [hasCroppedState, setHasCroppedState] = useState(false);

  const onCompletedCrop = async () => {
    if (!previewCanvasRef.current) {
      return;
    }

    if (
      completedCropState?.width &&
      completedCropState?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      canvasPreview(
        imgRef.current,
        previewCanvasRef?.current,
        completedCropState,
        1,
        0,
      );
    }
    setHasCroppedState(true);

    const base64Canvas = previewCanvasRef.current
      .toDataURL('image/jpeg')
      .split(';base64,')[1];

    const resize = await resizeBase64Image(base64Canvas);

    setBase64(resize);
  };

  const onBack = () => {
    setHasCroppedState(false);
    setBase64('');

    if (!hasCroppedState) {
      handleCloseModal();
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCropState(centerAspectCrop(width, height, 1));
  };

  return (
    <>
      {!hasCroppedState ? (
        <CropContainer>
          <CropImage
            crop={cropState}
            onChange={(_, percentCrop) => setCropState(percentCrop)}
            onComplete={c => setCompletedCropState(c)}
            aspect={aspectState}
          >
            <img
              ref={imgRef}
              alt={image.fileName}
              src={image?.path}
              onLoad={onImageLoad}
            />
          </CropImage>
        </CropContainer>
      ) : null}
      {completedCropState && (
        <Stack display={hasCroppedState ? 'flex' : 'none'} alignItems="center">
          <CanvasPreview ref={previewCanvasRef} />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <Stack direction="row" spacing={4}>
          <Stack spacing={1}>
            <PropLabel>Formato</PropLabel>
            <PropValue>
              {Array.from(acceptedTypes).join(', ').toLocaleUpperCase()}
            </PropValue>
          </Stack>
          <Stack spacing={1}>
            <PropLabel>Tamanho</PropLabel>
            <PropValue>{formatBytes(maxSize)}</PropValue>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="primary" onClick={onBack}>
            Cancelar
          </Button>
          {!hasCroppedState ? (
            <Button
              variant="contained"
              color="success"
              onClick={onCompletedCrop}
            >
              Prosseguir
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={handleSendImage}
            >
              Salvar
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default ImageCrop;
