import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

import { COLORS } from '../colors';
const MuiFilledInputTheme: {
  defaultProps?: ComponentsProps['MuiFilledInput'];
  styleOverrides?: ComponentsOverrides<any>['MuiFilledInput'];
  variants?: ComponentsVariants['MuiFilledInput'];
} = {
  variants: [
    {
      props: { color: 'success' },
      style: {
        border: `1px solid ${COLORS.SECONDARY.GREEN.MAIN}`,
      },
    },
    {
      props: { error: true },
      style: {
        border: `1px solid ${COLORS.SECONDARY.RED.MAIN}`,
      },
    },
  ],
  defaultProps: {},
  styleOverrides: {
    root: {
      background: COLORS.MONOCHROMATIC.GRAY1,
      borderRadius: 0,
      height: 48,
      padding: '0',
      ':before': {
        display: 'none',
      },
      ':after': {
        display: 'none',
      },
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      '&.Mui-focused': {
        border: `1px solid ${COLORS.PRIMARY.BLUE['-1']}`,
        background: COLORS.MONOCHROMATIC.GRAY1,
      },
      '.MuiFormHelperText-root': {
        color: 'red',
      },
      '&.Mui-disabled': {
        background: COLORS.MONOCHROMATIC.GRAY1,
      },
    },

    adornedEnd: {
      paddingRight: 8,
    },
    input: {
      paddingTop: 12,
      paddingBottom: 12,
      paddingRight: 12,
      paddingLeft: 12,
    },
  },
};

export default MuiFilledInputTheme;
