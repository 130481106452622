import React from 'react';
import { PaperProps } from '@mui/material';

import { Container } from './styles';
interface ContainerProps {
  alert?: boolean;
}

const GridListViewItem: React.FC<PaperProps & ContainerProps> = ({
  children,
  alert,
  ...props
}) => {
  return (
    <Container alert={alert || undefined} {...props}>
      {children}
    </Container>
  );
};

export default GridListViewItem;
