const resizeBase64Image = (base64Image: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = `data:image/jpeg;base64,${base64Image}`;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 284;
      canvas.height = 284;
      ctx?.drawImage(img, 0, 0, 284, 284);
      const resizedBase64Image = canvas.toDataURL('image/jpeg').split(',')[1];
      resolve(resizedBase64Image);
    };
    img.onerror = error => {
      reject(error);
    };
  });
};

export default resizeBase64Image;
