import { Icon } from '@hapvida/hapvida-core-components';
import { COLORS } from '@hapvida/hapvida-core-components/src/themes/colors';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { Container, Trigger } from './styles';

interface AttendantCardProps {
  phone?: string | undefined;
  mail?: string | undefined;
  messageWhatsapp?: string;
  profileImage?: string | undefined;
  name?: string | undefined;
  role?: string | undefined;
}

export const AttendantCard = ({
  phone,
  mail,
  messageWhatsapp,
  profileImage,
  name,
  role,
}: AttendantCardProps) => {
  const handleSendMail = useCallback(() => {
    window.open(`mailto:${mail}`);
  }, [mail]);

  const handleCall = useCallback(() => {}, []);

  const handleWhatsapp = useCallback(() => {
    window.open(
      `https://api.whatsapp.com/send?phone=${phone}&text=${messageWhatsapp}`,
    );
  }, [messageWhatsapp, phone]);

  return (
    <Container container gap={2}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Avatar src={profileImage} sx={{ width: 40, height: 40 }} />
        <Grid gap={0.5} direction="column">
          <Typography
            variant="text"
            color={COLORS.MONOCHROMATIC.GRAY4}
            fontSize={14}
          >
            {name}
          </Typography>
          <Typography variant="smallText" color={COLORS.MONOCHROMATIC.GRAY4}>
            {' '}
            <br />
            {role}
          </Typography>
        </Grid>
      </Stack>
      <Stack direction="row" alignItems="center" gap={6}>
        <Trigger gap={1} onClick={handleSendMail}>
          <Icon
            name="email-check-outline"
            variant="outlined"
            size={20}
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
          <Typography
            variant="text"
            display="block"
            color={COLORS.MONOCHROMATIC.GRAY4}
          >
            Enviar e-mail
          </Typography>
        </Trigger>
        {/* <Trigger gap={1} onClick={handleCall}>
          <Icon
            name="phone-fill"
            variant="outlined"
            size={20}
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
          <Typography
            variant="text"
            display="block"
            color={COLORS.MONOCHROMATIC.GRAY4}
          >
            Fazer ligação
          </Typography>
        </Trigger> */}
        {/* <Trigger gap={1} onClick={handleWhatsapp}>
          <Icon
            name="whatsapp"
            variant="outlined"
            size={20}
            color={COLORS.PRIMARY.ORANGE.MAIN}
          />
          <Typography
            variant="text"
            display="block"
            color={COLORS.MONOCHROMATIC.GRAY4}
          >
            Enviar whatsapp
          </Typography>
        </Trigger> */}
      </Stack>
    </Container>
  );
};
