import {
  styled as muiStyled,
  Typography,
  TypographyProps,
} from '@mui/material';

interface StyledTypographyProps extends TypographyProps {
  variantColor?: 'primary' | 'secondary' | 'tertiary';
}

export const StyledTypography = muiStyled(
  ({ variantColor, ...rest }: StyledTypographyProps) => (
    <Typography {...rest} />
  ),
)<StyledTypographyProps>(({ theme, variantColor }) => ({
  ...(variantColor && {
    color: theme.palette[variantColor]?.main,
  }),
}));
