import React from 'react';
import { Stack } from '@mui/material';
import { COLORS } from '../../themes/colors';
import formatDateISO from '../../utils/formatDateISO';

import Icon from '../Icon';
import Typography from '../Typography';

interface CalendarDisplayProps {
  date: string;
}

const CalendarDisplay: React.FC<CalendarDisplayProps> = ({ date }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center">
      <Icon name="calendar" size={24} color={COLORS.PRIMARY.BLUE.MAIN} />
      <Typography variant="text">
        {formatDateISO(date, "dd 'de' MMMM yyyy HH:mm")}
      </Typography>
    </Stack>
  );
};

export default CalendarDisplay;
