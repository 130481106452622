import { ScreenLoading } from '@hapvida/hapvida-core-components';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

const AppLazy = React.lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<ScreenLoading open />}>
      <AppLazy />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
