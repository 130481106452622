import { useState } from 'react';

import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Collapse from '@mui/material/Collapse';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import {
  Text,
  DotIcon,
  InnerStepsButton,
  StepperContainer,
  InnerButtonContent,
} from './styles';

import QuotationStatusInline from '../QuotationStatusInline';
import { QuotationStatusEnum, quotationEnumKeys } from '../../constants/enums';

import UserInformation from './components/User';
import InnerSteps from './components/InnerSteps';

import formatDateISO from '../../utils/formatDateISO';
import { COLORS } from '../../themes/colors';

interface QuotationHistoryBaseItem {
  createdAt: string;
  status: QuotationStatusEnum;
  userDetail?: {
    name: string;
    id: string;
  };
  observation: string;
}

interface QuotationHistoryItems extends QuotationHistoryBaseItem {
  subItens: QuotationHistoryBaseItem[];
}

interface QuotationStepsProps {
  steps: QuotationHistoryItems[];
}

const QuotationSteps = ({ steps }: QuotationStepsProps) => {
  const [showInnerSteps, setShowInnerSteps] = useState(-1);

  const QuotationStepIcon = (status: QuotationStatusEnum, color: string) => (
    <QuotationStatusInline
      status={status}
      variant="filled"
      statusColor={color}
      statusStyle={{
        fontWeight: 600,
        marginLeft: '8px',
      }}
    />
  );

  const handleToggleInnerSteps = (index: number) => {
    setShowInnerSteps(showInnerSteps === index ? -1 : index);
  };

  const renderUserInfo = (step: QuotationHistoryBaseItem) => {
    if (step.userDetail)
      return (
        <Grid item xs={12} ml={2}>
          <UserInformation name={step.userDetail.name} avatar="" />
        </Grid>
      );
  };

  const getSubStepsLabel = (length: number, index: number) => {
    if (index === showInnerSteps)
      return `Recolher ${length} edições neste status`;

    return `Mostrar ${length} edições neste status`;
  };

  return (
    <StepperContainer>
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={`step-${index}`} active>
            <StepLabel
              StepIconComponent={() =>
                QuotationStepIcon(
                  step.status,
                  quotationEnumKeys[step.status]?.color ?? COLORS.WHITE,
                )
              }
            />

            <StepContent>
              <Grid container spacing={0.5}>
                {renderUserInfo(step)}

                <Grid item xs={12} ml={2}>
                  <Text>
                    Iniciado{' '}
                    <strong>
                      {formatDateISO(
                        step.createdAt,
                        "dd 'de' MMMM yyyy 'às' HH:mm",
                      )}
                    </strong>
                  </Text>
                </Grid>

                {step.subItens.length > 0 && (
                  <InnerStepsButton
                    showHandle={true}
                    variant="text"
                    disableRipple
                    onClick={() => {
                      handleToggleInnerSteps(index);
                    }}
                  >
                    <InnerButtonContent showHandle={true}>
                      <DotIcon>...</DotIcon>
                      {getSubStepsLabel(step.subItens.length, index)}
                    </InnerButtonContent>
                  </InnerStepsButton>
                )}

                <Grid item xs={12}>
                  <Collapse
                    in={showInnerSteps === index && step.subItens.length > 0}
                    timeout="auto"
                    unmountOnExit
                  >
                    <InnerSteps
                      mainStatus={step.status}
                      additionalInformation={step.subItens}
                      QuotationStepIcon={QuotationStepIcon}
                    />
                  </Collapse>
                </Grid>
              </Grid>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </StepperContainer>
  );
};

export default QuotationSteps;
