import { CircularProgress } from '@mui/material';
import React from 'react';

import { Controller } from 'react-hook-form';
import { COLORS } from '../../themes/colors';

import Icon from '../Icon';
import {
  StyledFormControl,
  StyledInputLabel,
  StyledNativeSelect,
} from './styles';

interface SelectProps {
  label?: string;
  options: Array<{
    value: string | number;
    key: string;
    disabled?: boolean;
  }>;
  defaultValue?: string | number;
  placeholder?: string;
  name: string;
  control: any;
  required?: boolean;
  borderless?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  color?: string;
  activeCompany?: boolean;
}

const SelectInput: React.FC<SelectProps> = ({
  color = COLORS.BLACK,
  label,
  options,
  defaultValue,
  placeholder,
  name,
  control,
  required,
  borderless,
  isLoading,
  disabled,
  activeCompany = false,
}) => {
  return (
    <StyledFormControl disabled={disabled}>
      {label && (
        <StyledInputLabel required={required}>{label}</StyledInputLabel>
      )}
      <Controller
        name={name}
        render={({ field }) => (
          <StyledNativeSelect
            sx={{
              color,
            }}
            required={required}
            borderless={borderless}
            {...field}
            defaultValue={defaultValue}
            IconComponent={() =>
              isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Icon
                  name="chevron-down"
                  size={22}
                  style={{
                    minWidth: 24,
                  }}
                  color={
                    activeCompany === true
                      ? COLORS.PRIMARY.BLUE.MAIN
                      : COLORS.MONOCHROMATIC.GRAY4
                  }
                />
              )
            }
            disabled={isLoading || disabled}
          >
            <option hidden>{placeholder}</option>
            {options.map(option => (
              <option
                key={option.key}
                disabled={option.disabled ?? false}
                value={option.value}
              >
                {option.key}
              </option>
            ))}
          </StyledNativeSelect>
        )}
        control={control}
      />
    </StyledFormControl>
  );
};

export default SelectInput;
