import { Box, Grid, Paper, Stack, Tabs, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Date, QuantityFiles, StyledBox, StyledTab } from './styles';
import Icon from '../Icon';
import File from '../File';
import formatDateISO from '../../utils/formatDateISO';
import { COLORS } from '../../themes/colors';
import DragAndDrop from '../DragAndDrop';

export interface IFile {
  path: string;
  fileName: string;
  size: string | number;
  type?: string;
  lines?: number;
  uploadName?: string;
  date: string;
}

export interface TabsProps {
  label: string;
  content?: IFile[];
  children?: ReactNode;
  editable?: boolean;
  hasUpload?: boolean;
  onDeleteFile?: (file?: IFile) => void;
}

interface FilesTabsProps {
  title: string;
  date: string | undefined;
  tabs: TabsProps[] | undefined;
  maxLabelLength?: number;
  isEditQuotation?: boolean;
  isEditAdditionalFiles?: boolean;
  onEdit?: () => void;
  onDownload?: (file: IFile) => void;
  onUpload?: (file?: IFile) => void;
  children?: React.ReactChild;
}

export const FilesTabs = ({
  title,
  date,
  maxLabelLength,
  onEdit,
  isEditQuotation = true,
  isEditAdditionalFiles,
  onDownload,
  onUpload,
  tabs,
  children,
}: FilesTabsProps) => {
  const [value, setValue] = useState(0);

  if (!tabs) return <></>;

  function TabPanel(props: any) {
    const { children, index, hasUpload, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && (
          <StyledBox>
            <Stack gap={2}>
              {children}
              {hasUpload && (
                <>
                  {isEditAdditionalFiles && (
                    <DragAndDrop
                      onChange={file => onUpload && onUpload(file)}
                      value={undefined}
                    />
                  )}
                </>
              )}
            </Stack>
          </StyledBox>
        )}
      </div>
    );
  }

  return (
    <>
      <Grid item>
        <Paper sx={{ borderRadius: '8px' }}>
          <Stack gap={2}>
            <Grid
              sx={{ p: 3, pb: 0 }}
              item
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack width="100%">
                <Typography variant="subtitle1">{title}</Typography>
                {date ? (
                  <Date>
                    Realizado{' '}
                    <strong>
                      {formatDateISO(date, "dd 'de' MMMM 'de' yyyy")}
                    </strong>
                  </Date>
                ) : null}
              </Stack>
              {isEditQuotation && (
                <>
                  {onEdit && (
                    <Icon
                      name="edit"
                      variant="outlined"
                      onClick={onEdit}
                      size={20}
                      color={COLORS.MONOCHROMATIC.GRAY4}
                    />
                  )}
                </>
              )}
            </Grid>

            <Grid item>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  marginLeft: 2,
                }}
              >
                <Tabs value={value}>
                  {tabs.map((tab, index) => (
                    <StyledTab
                      key={tab.label}
                      label={
                        <Stack direction="row" gap={1} alignItems="center">
                          <Typography fontSize={14}>{tab.label}</Typography>
                          {tab.content!.length > 1 && (
                            <QuantityFiles variant="smallText">
                              {tab.content!.length}
                            </QuantityFiles>
                          )}
                        </Stack>
                      }
                      onClick={() => setValue(index)}
                    />
                  ))}
                </Tabs>
              </Box>
              {tabs.map(({ onDeleteFile, ...tab }, index) => (
                <TabPanel
                  key={tab.label}
                  hasUpload={tab.hasUpload}
                  value={index}
                  index={index}
                >
                  {tab.content!.map(file => (
                    <File
                      key={file.fileName}
                      filename={file.fileName}
                      date={file.date}
                      size={file.size}
                      lines={file.lines}
                      maxLabelLength={maxLabelLength}
                      positionTooltip="bottom"
                      onDownload={() => onDownload && onDownload(file)}
                      onDelete={
                        isEditAdditionalFiles
                          ? () => onDeleteFile && onDeleteFile(file)
                          : undefined
                      }
                    />
                  ))}
                  {tab.children}
                </TabPanel>
              ))}

              {children && <Stack sx={{ p: 3, pt: 0 }}>{children}</Stack>}
            </Grid>
          </Stack>
        </Paper>
      </Grid>
    </>
  );
};
