import {
  ComponentsProps,
  ComponentsVariants,
  ComponentsOverrides,
} from '@mui/material';

import { COLORS } from '../colors';
const MuiInputLabelTheme: {
  defaultProps?: ComponentsProps['MuiInputLabel'];
  styleOverrides?: ComponentsOverrides<any>['MuiInputLabel'];
  variants?: ComponentsVariants['MuiInputLabel'];
} = {
  variants: [],

  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {
      transform: 'none',
      position: 'initial',
      marginBottom: 8,
      color: COLORS.MONOCHROMATIC.GRAY4,
      '&.Mui-focused': {
        color: COLORS.MONOCHROMATIC.GRAY4,
      },
      '&.Mui-required': {
        color: COLORS.PRIMARY.BLUE['-2'],
        '.MuiFormLabel-asterisk': {
          color: COLORS.PRIMARY.BLUE['-2'],
        },
      },
      '&.Mui-error': {
        color: COLORS.MONOCHROMATIC.GRAY4,
      },
    },
    asterisk: {
      color: COLORS.MONOCHROMATIC.GRAY4,
    },
  },
};

export default MuiInputLabelTheme;
