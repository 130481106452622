import { Stack, Tooltip } from '@mui/material';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { COLORS } from '../../themes/colors';
import Icon from '../Icon';
import { StyledButton, StyledLink } from './styles';

interface LinkWithCopyProps {
  color?: string;
  hideCopyButton?: boolean;
  url: string;
  onClick?: () => void;
}

const LinkWithCopy = ({
  color = COLORS.PRIMARY.BLUE.MAIN,
  hideCopyButton,
  url,
  onClick,
}: LinkWithCopyProps) => {
  const [copied, setCopied] = useState(false);

  const copy = useCallback(() => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }, []);

  return (
    <Stack direction="row" spacing={2}>
      <StyledLink href={url} target="_blank">
        {url}
      </StyledLink>
      {!hideCopyButton ? (
        <CopyToClipboard text={url} onCopy={copy}>
          <Tooltip
            title={copied ? 'Copiado!' : 'Copiar para área de transferência'}
          >
            <StyledButton variant="outlined">
              Copiar
              <Icon name="copy" size={20} color={color} />
            </StyledButton>
          </Tooltip>
        </CopyToClipboard>
      ) : null}
    </Stack>
  );
};

export default LinkWithCopy;
