import { useController } from 'react-hook-form';
import DragAndDrop, { DragAndDropProps } from '../DragAndDrop';

type ControlledDragAndDropProps<
  Multiple extends boolean | undefined = undefined,
> = {
  control: any;
  name: string;
  showHeader?: boolean;
} & Omit<DragAndDropProps<Multiple>, 'value' | 'onChange'>;

const ControlledDragAndDrop = <Multiple extends boolean = false>({
  control,
  name,
  showHeader,
  ...props
}: ControlledDragAndDropProps<Multiple>) => {
  const {
    field: { value, onChange },
  } = useController({ control, name });

  return (
    <DragAndDrop
      showHeader={showHeader}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default ControlledDragAndDrop;
