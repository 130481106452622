import { FC, ReactNode, useRef } from "react";

import {
  TableBodyCell,
  TableHeadCell,
  TableOrder,
} from "@hapvida/hapvida-core-components";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useMediaQuery, useTheme } from "@mui/material";

import { StyledTableHead, StyledTableRow } from "./styles";

type TableHeadParams = {
  id: number;
  label: string;
  name: string;
  hide?: boolean;
};

interface TableViewProps {
  list: ReactNode[];
  TableHeadColumns: TableHeadParams[];
  tableOrder?: TableOrder | null;
  onOrderChange?(order: "asc" | "desc", name: string): void;
  onTableOrderChange: (tableOrder: TableOrder | null) => void;
  hasInfiniteScroll?: boolean;
  loadNextPage?: () => Promise<void>;
  maxHeight?: number;
  maxWidth?: number;
}

const TableView: FC<TableViewProps> = ({
  list,
  TableHeadColumns,
  tableOrder,
  onOrderChange,
  hasInfiniteScroll = false,
  loadNextPage,
  maxHeight = 400,
  maxWidth = 0,
}) => {
  const tableRef = useRef({} as HTMLDivElement);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dynamicRow = (item: any) => {
    const cells: any = [];

    TableHeadColumns.forEach((column: TableHeadParams, index) => {
      if (item[column.name]) {
        const cell = (
          <TableBodyCell key={Math.random() * (10 - index) + index}>
            {item[column.name]}
          </TableBodyCell>
        );
        cells.push(cell);
      }
    });

    return (
      <StyledTableRow key={item.id} hover>
        {cells}
      </StyledTableRow>
    );
  };

  const onScroll = () => {
    if (tableRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
      if (
        scrollTop + clientHeight === scrollHeight &&
        hasInfiniteScroll &&
        loadNextPage
      )
        loadNextPage();
    }
  };

  return (
    <TableContainer
      ref={tableRef}
      onScroll={onScroll}
      sx={
        isMobile ? { maxWidth: 300, maxHeight } : { maxWidth: 2000, maxHeight }
      }
    >
      <Table stickyHeader>
        <StyledTableHead>
          <TableRow>
            {TableHeadColumns.map((column) => (
              <TableHeadCell
                key={column.id}
                label={column.label}
                name={column.name}
                hide={column.hide}
                order={tableOrder}
                onOrderChange={onOrderChange}
              />
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>{list.map((item) => dynamicRow(item))}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableView;
