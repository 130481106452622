import { StyledTextArea } from './styles';
import { TextFieldProps } from '@mui/material';

type TextInputAreaProps = TextFieldProps & {
  control?: any;
  mask?: Function;
};
export const TextInputArea = ({
  name,
  label,
  placeholder,
  required = true,
  helperText,
  control,
  fullWidth = true,
  multiline = true,
  inputProps,
  variant = 'filled',
  InputProps,
  value,
  onChange,
  mask,
}: TextInputAreaProps) => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;

    if (mask) e.target.value = mask(value);

    onChange?.(e);
  };
  return (
    <StyledTextArea
      multiline={multiline}
      inputProps={{ ...inputProps }}
      InputProps={{ ...InputProps }}
      onChange={handleChange}
      value={value}
      name={name}
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      control={control}
      fullWidth={fullWidth}
      variant={variant}
    />
  );
};
