import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  PageTitleContainer,
  AppBar,
  AppBarContainer,
  SubRouteName,
  MobileDivider,
  MenuButton,
  RouteName,
  IconWrapper,
} from './styles';

import UserMenu from '../UserMenu';
import Icon from '../Icon';
import Modal from '../Modal';
import Typography from '../Typography';

export type RouteOverride = {
  route: PageHeaderProps['route'];
  subRoute?: PageHeaderProps['subRoute'];
};

export const isRouteOverride = (value: unknown): value is RouteOverride => {
  return typeof value === 'object' && value !== null && 'route' in value;
};

interface PageHeaderProps {
  route?: {
    name?: string;
    icon?: JSX.Element;
    navigateBackPath?: string;
  };
  subRoute?: string | null;
  user?: {
    profile: string;
    token: string;
    name: string;
    type: string;
  } | null;
  subHeader?: JSX.Element;
  drawerOpen?: boolean;
  toggleDrawer?: () => void;
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
  drawerOpenWidth?: number;
  drawerCloseWidth?: number;
  onLogOut?(): void;
  totalQuotationsAndCompany?: number;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  route: defaultRoute,
  subRoute: defaultSubRoute,
  user,
  subHeader,
  drawerOpen,
  toggleDrawer,
  innerRef,
  drawerOpenWidth,
  drawerCloseWidth,
  onLogOut,
  totalQuotationsAndCompany,
}) => {
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const handleLogoutConfirmation = () =>
    setShowLogoutConfirmation(!showLogoutConfirmation);

  const navigate = useNavigate();

  const { state } = useLocation();
  const route = useMemo(
    () => (isRouteOverride(state) ? state.route : defaultRoute),
    [defaultRoute, state],
  );
  const subRoute = useMemo(
    () => (isRouteOverride(state) ? state.subRoute : defaultSubRoute),
    [defaultSubRoute, state],
  );
  return (
    <>
      <Modal
        icon="alert"
        secondaryButtonText="Fechar"
        mainButtonText="Sair mesmo assim"
        title="Atenção! As informações não finalizadas poderão ser perdidas."
        mainAction={onLogOut}
        open={showLogoutConfirmation}
        handleCloseModal={handleLogoutConfirmation}
      >
        <Typography variant="body1" fontSize={14}>
          Tem certeza que desejar sair?
        </Typography>
        <Typography variant="body1" fontSize={14}>
          Ao sair, informações não finalizadas poderão ser perdidas.
        </Typography>
      </Modal>

      <AppBar
        innerRef={innerRef}
        position="fixed"
        open={drawerOpen}
        drawerOpenWidth={drawerOpenWidth}
        drawerCloseWidth={drawerCloseWidth}
        hasSubHeader={totalQuotationsAndCompany !== 0 ? !!subHeader : false}
      >
        <AppBarContainer maxWidth="xl">
          <Grid container>
            <Grid item xs={8} md={6}>
              {route && (
                <>
                  {subRoute ? (
                    <PageTitleContainer
                      gap={1}
                      direction="row"
                      alignItems="center"
                    >
                      {route.navigateBackPath ? (
                        <>
                          <IconWrapper
                            onClick={() =>
                              navigate(`${route.navigateBackPath}`)
                            }
                          >
                            {typeof route.icon === 'string' ? (
                              <Icon name={route.icon} />
                            ) : (
                              route.icon
                            )}
                          </IconWrapper>
                        </>
                      ) : typeof route.icon === 'string' ? (
                        <Icon name={route.icon} />
                      ) : (
                        <Icon name={route?.icon?.props?.name} size={32} />
                      )}

                      <Icon name="chevron-right" size={16} />
                      <SubRouteName>{subRoute}</SubRouteName>
                      <Icon name="chevron-right" size={16} />
                      <RouteName variant="h6">{route.name}</RouteName>
                    </PageTitleContainer>
                  ) : (
                    <PageTitleContainer
                      gap={1}
                      direction="row"
                      alignItems="center"
                    >
                      {route.navigateBackPath ? (
                        <>
                          <IconWrapper
                            onClick={() =>
                              navigate(`${route.navigateBackPath}`)
                            }
                          >
                            <Icon name={route?.icon?.props?.name} size={32} />
                          </IconWrapper>

                          <RouteName variant="h6">{route.name}</RouteName>
                        </>
                      ) : (
                        <>
                          <Icon name={route?.icon?.props?.name} size={32} />
                          <RouteName variant="h6">{route.name}</RouteName>
                        </>
                      )}
                    </PageTitleContainer>
                  )}
                </>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              sm={4}
              alignItems="center"
              justifyContent={{ xs: 'space-between', sm: 'flex-end' }}
              order={{ xs: -1, sm: 0 }}
            >
              <Grid item display={{ sm: 'none' }}>
                <MenuButton
                  variant="text"
                  startIcon={<Icon name="drawer" />}
                  onClick={toggleDrawer}
                >
                  Menu
                </MenuButton>
              </Grid>
              <Grid item>
                <UserMenu
                  username={user?.name}
                  role={user?.profile}
                  url=""
                  onLogOut={handleLogoutConfirmation}
                />
              </Grid>
              <MobileDivider />
            </Grid>
            {subHeader}
          </Grid>
        </AppBarContainer>
      </AppBar>
    </>
  );
};

export default PageHeader;
