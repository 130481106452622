import { ButtonClasses } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { COLORS } from '../colors/index';

const MuiButtonTheme: {
  styleOverrides: Partial<
    OverridesStyleRules<keyof ButtonClasses, 'MuiButton'>
  >;
} = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      padding: '16px 20px',
      fontSize: 14,
      fontWeight: 400,
      height: 48,
      boxShadow: 'none',
      ':disabled': {
        backgroundColor: COLORS.DISABLED.BACKGROUND,
        border: `1px solid ${COLORS.DISABLED.BORDER}`,
      },
      ':hover': {
        boxShadow: 'none',
      },
      textTransform: 'none',
    },
    outlined: {
      color: COLORS.PRIMARY.BLUE.MAIN,
      border: `1px solid ${COLORS.PRIMARY.BLUE.MAIN}`,
      boxShadow: 'none',
      ':hover': {
        color: COLORS.PRIMARY.BLUE['+1'],
        border: `1px solid ${COLORS.PRIMARY.BLUE['+1']}`,
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    },
    sizeSmall: {
      padding: '12px 24px',
      fontSize: 12,
    },
  },
};

export default MuiButtonTheme;
