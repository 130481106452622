import { createContext, Dispatch, SetStateAction } from 'react';
import { Gender } from '../constants/enums';
import { FiltersGroup } from '../hooks/useDrawerFilter';

export interface InitialFiltersConfig {
  [filterName: string]: {
    label: string;
    value: string;
  }[];
}

export type LabelNamingConfig = Record<
  string,
  | string
  | { singular?: string; plural?: string; gender?: Gender }
>;

export interface DrawerFilterContextData {
  activeFilters: FiltersGroup;
  setActiveFilters: Dispatch<SetStateAction<FiltersGroup>>;
  provFilters: FiltersGroup;
  setProvFilters: Dispatch<SetStateAction<FiltersGroup>>;
  initialFilters: FiltersGroup;
  setInitialFilters: Dispatch<SetStateAction<FiltersGroup>>;
  cleanFilters: () => void;
  applyFilters: (callback?: (filters: FiltersGroup) => void) => void;
  filterCount: number;
  getInitialFilter: (
    initialFilter: InitialFiltersConfig,
    labelNaming?: LabelNamingConfig,
  ) => FiltersGroup;
  handleFilterChange: (filters: FiltersGroup) => void;
  ignoreFilters: string[];
  register: (filterKey: string) => void;
  unregister: (filterKey: string) => void;
}

export const DrawerFilterContext = createContext<DrawerFilterContextData>(
  {} as DrawerFilterContextData,
);
