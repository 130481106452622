import React from 'react';
import { TypographyProps } from '@mui/material';

import { StyledTypography } from './styled';

interface Props extends TypographyProps {
  component?: React.ElementType<any> | any;
  color?: 'primary' | 'secondary' | 'tertiary';
}

const Typography: React.FC<Props> = ({ children, color, ...props }) => {
  return (
    <StyledTypography variantColor={color} {...props}>
      {children}
    </StyledTypography>
  );
};

export default Typography;
