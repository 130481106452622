import { FC, useState } from 'react';

import { DialogProps } from '@mui/material/Dialog';

import { Box, Stack } from '@mui/material';

import { formatBytes } from '../../utils/formatBytes';
import DragAndDrop from '../DragAndDrop';
import IDragAndDropFile from '../DragAndDrop/dtos/IDragDropFile';
import { IconName } from '../Icon/types';
import ImageCrop from '../ImageCrop';
import { ModalWrapper, PropLabel, PropValue, Title } from './styles';

interface ModalProps extends DialogProps {
  type?: 'default' | 'alert' | 'success' | 'gray';
  icon?: IconName;
  open: boolean;
  mainButtonText?: string;
  secondaryButtonText?: string;
  mainAction?: () => void;
  handleCloseModal: () => void;
  hasCropped?: boolean;
  setBase64?: React.Dispatch<React.SetStateAction<string>>;
  handleSendImage: () => void;
  maxSize?: number;
  acceptedTypes?: string | string[];
}

const AvatarPickerUser: FC<ModalProps> = ({
  icon,
  open,
  mainButtonText,
  secondaryButtonText,
  handleCloseModal,
  mainAction,
  children,
  type = 'default',
  hasCropped = true,
  setBase64,
  maxSize = 2 * 1.049e6,
  acceptedTypes = ['jpg', 'png'],
  handleSendImage,
  ...rest
}) => {
  const [image, setImage] = useState<IDragAndDropFile>();
  const changeImage = (type: IDragAndDropFile | undefined) => {
    if (type) {
      if (!hasCropped) {
        return setImage(type), handleCloseModal();
      }
      return setImage(type);
    }
  };

  const handleCloseModalImage = () => {
    handleCloseModal();
    setTimeout(() => {
      setImage(undefined);
    }, 200);
  };

  return (
    <ModalWrapper
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      {...rest}
    >
      <Title id="dialog-title">Imagem de perfil</Title>

      {hasCropped && setBase64 && image ? (
        <ImageCrop
          image={image}
          maxSize={maxSize}
          acceptedTypes={acceptedTypes}
          setBase64={setBase64}
          handleCloseModal={handleCloseModalImage}
          handleSendImage={handleSendImage}
        />
      ) : (
        <>
          <Box sx={{ marginTop: '-16px' }}>
            <DragAndDrop
              dropAreaStyles={{ minHeight: '293px' }}
              value={image}
              onChange={file => {
                changeImage(file);
              }}
              acceptedTypes={acceptedTypes}
              maxSize={maxSize}
              isAvatar
            />
          </Box>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack direction="row" spacing={4}>
              <Stack spacing={1}>
                <PropLabel>Formato</PropLabel>
                <PropValue>
                  {Array.from(acceptedTypes).join(', ').toLocaleUpperCase()}
                </PropValue>
              </Stack>
              <Stack spacing={1}>
                <PropLabel>Tamanho</PropLabel>
                <PropValue>{formatBytes(maxSize)}</PropValue>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </ModalWrapper>
  );
};

export default AvatarPickerUser;
