import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';

import Icon from '../Icon';

import { StyledDrawer, DrawerHeader, Title, StyledButton } from './styles';
import { COLORS } from '../../themes/colors';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface HapDrawerProps {
  title: string;
  width?: number;
  anchor: Anchor;
  openDrawer: boolean;
  children: ReactNode;
  setDrawerOpen: () => void;
}

const HapDrawer = ({
  title,
  width = 653,
  anchor,
  children,
  openDrawer,
  setDrawerOpen,
}: HapDrawerProps) => {
  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setDrawerOpen();
  };

  return (
    <div>
      <StyledDrawer
        anchor={anchor}
        open={openDrawer}
        onClose={setDrawerOpen}
        elevation={0}
        sx={{ flexShrink: 1 }}
      >
        <Box
          sx={{
            width: anchor === 'top' || anchor === 'bottom' ? 'auto' : width,
          }}
          height="100%"
          role="presentation"
          onKeyDown={toggleDrawer}
          bgcolor={COLORS.MONOCHROMATIC.GRAY1}
        >
          <DrawerHeader anchor={anchor}>
            <StyledButton onClick={toggleDrawer}>
              <Icon name="close" color={COLORS.PRIMARY.ORANGE.MAIN} size={20}/>
            </StyledButton>
            <Title variant="subtitle1">{title}</Title>
          </DrawerHeader>
          <Box height="calc(100% - 70px)">{children}</Box>
        </Box>
      </StyledDrawer>
    </div>
  );
};

export default HapDrawer;
